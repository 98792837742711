import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import 'react-tooltip/dist/react-tooltip.css'
import TagManager from 'react-gtm-module';
import { sentryInit } from './lib/sentry.ts'

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-NFB4WHML'
}
TagManager.initialize(tagManagerArgs)

// Initialize Sentry
sentryInit();

let reactDomRoot: ReactDOM.Root

const startConfigurator = () => {
  if (reactDomRoot === undefined) {
    const body = document.getElementsByTagName('body')[0]
    body.setAttribute('style', 'overflow: hidden;')
    document
      ?.getElementById('weland-configurator-root')
      ?.setAttribute(
        'style',
        'position: absolute;top: 0;bottom: 0;right: 0;left: 0;z-index: 9999; overflow: auto; overflow-x: hidden;'
      )
    reactDomRoot = ReactDOM.createRoot(
      document.getElementById('weland-configurator-root') as HTMLElement
    )

    reactDomRoot.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  }
}

const closeConfigurator = () => {
  reactDomRoot.unmount()
}

document.addEventListener('start-configurator', startConfigurator)
document.addEventListener('close-configurator', closeConfigurator)
document.dispatchEvent(new Event('start-configurator'))
