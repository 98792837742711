import React, { createRef, useEffect, useState } from 'react'
import { Group, Line } from 'react-konva'
import { StoreState, useBoundStore } from '~/store'
import PanelAreas from './panelarea/PanelAreas'
import EdgeZone from './EdgeZone'
import { AxiosResponse } from 'axios'
import { getRoofPoints } from '~/utils/configurator'
import PanelAreaSections from './panelarea/PanelAreaSections'
import { shallow } from 'zustand/shallow'
import { solarPanelClient } from '~/http/api'
import { KonvaEventObject } from 'konva/lib/Node'
import {
  getCoordinatesFromPoints,
  getMaxOfEvenIndex,
  getMaxOfOddIndex,
  getMinOfEvenIndex,
  getMinOfOddIndex,
  getPositionImageScale
} from '~/lib/utils'
import Konva from 'konva'
import DistanceLabels from './DistanceLabels'
import { cacheNode, clearNodeCache } from '~/lib/canvasUtils'

type Props = {
  position: Position
  children?: React.ReactNode
  scale: number
  imageView?: boolean
  // roofRef?: React.RefObject<Konva.Group>
  isScrolling: boolean
  isDragging: boolean
}

const Roof = React.memo(
  ({ position, scale, imageView, isScrolling, isDragging }: Props) => {
    const {
      roof,
      conditions,
      roofCoordinates,
      positionsOverviewImageView,
      setRoofImageScale,
      setRoofCoordinates
    } = useBoundStore(
      (state: StoreState) => ({
        roof: state.roof,
        conditions: state.conditions,
        roofCoordinates: state.roofCoordinates,
        positionsOverviewImageView: state.positionsOverviewImageView,
        setRoofImageScale: state.setRoofImageScale,
        setRoofCoordinates: state.setRoofCoordinates
      }),
      shallow
    )

    const [roofPoints, setRoofPoints] = useState<number[]>([])
    const [coordinates, setCoordinates] = useState<Position[]>([])
    const [currentRoof, setCurrentRoof] = useState<Konva.Group | null>(null)
    const roofRef = createRef<Konva.Group>()

    useEffect(() => {
      if (currentRoof && (isScrolling || isDragging)) {
        cacheNode(currentRoof, 1)
      } else if (currentRoof && !isScrolling && !isDragging) {
        clearNodeCache(currentRoof)
      }
    }, [isScrolling, isDragging])

    useEffect(() => {
      if (roofRef.current !== null && currentRoof === null) {
        setCurrentRoof(roofRef.current)
      }
    }, [roofRef])

    const handleResponse = (res: AxiosResponse) => {
      setCoordinates(res.data.coordinates)
    }

    useEffect(() => {
      const abortController = new AbortController()
      const { shape, measurementA, measurementB, measurementC = 0 } = roof
      const points = getRoofPoints(shape, {
        a: measurementA,
        b: measurementB,
        c: measurementC
      })
      setRoofPoints(points)
      if (
        roof.type !== '' &&
        roof.slope >= 0 &&
        measurementA > 0 &&
        measurementB > 0
      ) {
        solarPanelClient
          .post(
            '/edge-zone',
            {
              roof,
              conditions
            },
            {
              signal: abortController.signal
            }
          )
          .then(handleResponse)
          .catch((err) => {
            console.log(err)
          })
      }
      return () => {
        abortController.abort()
      }
    }, [roof])

    useEffect(() => {
      if (roofPoints.length > 0) {
        const maxYValue = getMaxOfOddIndex(roofPoints)
        const minYValue = getMinOfOddIndex(roofPoints)
        const maxXValue = getMaxOfEvenIndex(roofPoints)
        const minXValue = getMinOfEvenIndex(roofPoints)
        const xValue = maxXValue - minXValue
        const yValue = maxYValue - minYValue
        const scaleValue = yValue > xValue ? yValue : xValue
        const scale = getPositionImageScale(scaleValue)
        setRoofImageScale(scale)
      }
      setRoofCoordinates(getCoordinatesFromPoints(roofPoints))
    }, [roofPoints])

    if (roofCoordinates?.length === 0) return null

    return (
      <Group
        ref={roofRef}
        x={position.x}
        y={position.y}
        onMouseEnter={(event: KonvaEventObject<MouseEvent>) => {
          const stage = event.target.getStage()
          if (stage) {
            const containerStyle = stage.container().style
            containerStyle.cursor = 'move'
          }
        }}
        onMouseLeave={(event: KonvaEventObject<MouseEvent>) => {
          const stage = event.target.getStage()
          if (stage) {
            const containerStyle = stage.container().style
            containerStyle.cursor = 'default'
          }
        }}
      >
        <Line
          points={roofPoints}
          name="roof"
          fill="#fff"
          closed
          stroke="#696969"
          strokeEnabled={imageView === true && positionsOverviewImageView}
        />
        <EdgeZone
          coordinates={coordinates}
          scale={scale}
          imageView={imageView}
        />
        <Group>
          <PanelAreas
            scale={scale}
            imageView={imageView}
            isScrolling={isScrolling}
            roofPosition={position}
          />
        </Group>
        <Group>
          <PanelAreaSections imageView={imageView} />
          <Group>
            <DistanceLabels />
          </Group>
        </Group>
      </Group>
    )
  }
)

export default Roof
