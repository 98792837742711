import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import Drawer from './Drawer'
import FormPosition from './form/FormPosition'
import Section from './form/Section'
import FormRoofMaterial from './form/FormRoofMaterial'
import FormRoofProperties from './form/FormRoofProperties'
import { useEffect, useState } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LowSlopeRoofMaterialForm } from './form/lowSlope/LowSlopeMaterialForm'
import LowSlopePropertiesForm from './form/lowSlope/LowSlopePropertiesForm'

const DrawerConditions = React.memo(() => {
  const { t } = useTranslation()
  const {
    conditions,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    setShowConditions,
    showConditions
  } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.isRoofPropertiesDataValid,
      setShowConditions: state.setShowConditions,
      showConditions: state.showConditions
    }),
    shallow
  )
  const { configurationId } = useParams()

  const [isPositionOpen, setIsPositionOpen] = useState(false)
  const [isRoofMaterialOpen, setIsRoofMaterialOpen] = useState(false)
  const [isRoofPropertiesOpen, setIsRoofPropertiesOpen] = useState(false)

  useEffect(() => {
    if (configurationId === undefined) {
      if (!isPositionDataValid) {
        setIsPositionOpen(true)
      } else if (!isRoofMaterialDataValid) {
        setIsRoofMaterialOpen(true)
      } else if (!isRoofPropertiesDataValid) {
        setIsRoofPropertiesOpen(true)
      }
    }
  }, [isPositionDataValid, isRoofMaterialDataValid, isRoofPropertiesDataValid])

  return (
    <Drawer
      isOpen={showConditions}
      handleClose={() => setShowConditions(false)}
    >
      <Section
        isValid={isPositionDataValid}
        isOpen={isPositionOpen}
        toggleOpen={() => setIsPositionOpen(!isPositionOpen)}
        title={t('Position')}
        step="1"
      >
        <FormPosition
          isOpen={isPositionOpen}
          closeSection={() => setIsPositionOpen(false)}
          openNextSection={() =>
            setIsRoofMaterialOpen(!isRoofMaterialDataValid)
          }
        />
      </Section>
      <hr className="my-6 w-full bg-grayscale-25" />
      <Section
        isValid={isRoofMaterialDataValid}
        isOpen={isRoofMaterialOpen}
        toggleOpen={() => setIsRoofMaterialOpen(!isRoofMaterialOpen)}
        title={t('Material')}
        step="2"
        disabled={!isPositionDataValid}
      >
        {conditions.configurationSystem === 'parallel' ? (
          <FormRoofMaterial
            isOpen={isRoofMaterialOpen}
            closeSection={() => setIsRoofMaterialOpen(false)}
            openNextSection={() =>
              setIsRoofPropertiesOpen(!isRoofPropertiesDataValid)
            }
          />
        ) : (
          <LowSlopeRoofMaterialForm
            isOpen={isRoofMaterialOpen}
            closeSection={() => setIsRoofMaterialOpen(false)}
            openNextSection={() =>
              setIsRoofPropertiesOpen(!isRoofPropertiesDataValid)
            }
          />
        )}
      </Section>
      <hr className="my-6 w-full bg-grayscale-25" />
      <Section
        isValid={isRoofPropertiesDataValid}
        isOpen={isRoofPropertiesOpen}
        toggleOpen={() => setIsRoofPropertiesOpen(!isRoofPropertiesOpen)}
        className="mb-6"
        title={t('Takegenskaper')}
        step="3"
        disabled={!(isPositionDataValid && isRoofMaterialDataValid)}
      >
        {conditions.configurationSystem === 'parallel' ? (
          <FormRoofProperties
            isOpen={isRoofPropertiesOpen}
            closeSection={() => setIsRoofPropertiesOpen(false)}
          />
        ) : (
          <LowSlopePropertiesForm
            isOpen={isRoofPropertiesOpen}
            closeSection={() => setIsRoofPropertiesOpen(false)}
          />
        )}
      </Section>
    </Drawer>
  )
})

export default DrawerConditions
