import { StateCreator } from 'zustand'

const initialState: Summary = {
  numberOfPanels: 0,
  price: 0,
  originalPrice: 0,
  totalPanelWeight: 0,
  totalMountingWeight: 0,
  loadConditions: {
    windCoefficient: 0,
    snowCoefficient: 0
  },
  projectDetails: {
    attachment: '',
    consequenceClass: '',
    date: '',
    dimensioning: '',
    system: ''
  }
}

export interface SummarySlice {
  summary: Summary
  setSummary: (summary: Summary) => void
}

export const createSummarySlice: StateCreator<SummarySlice> = (set) => ({
  summary: initialState,
  setSummary: (summary: Summary) => set(() => ({ summary }))
})
