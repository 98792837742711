import { z } from 'zod'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './form/fields/Input'
import Button from './buttons/Button'
import { zodI18nMap } from 'zod-i18n-map'
import Select from './form/fields/Select'
import { profileClient } from '../http/api'
import { AxiosResponse } from 'axios'
import LoadingCart from './cart/LoadingCart'
import FormChangePassword from './form/FormChangePassword'
import BasicRadio from './form/fields/BasicRadio'
import { StoreState, useBoundStore } from '../store'
import i18n from '../lib/i18n'
import { useNavigate } from 'react-router-dom'
const { t } = i18n

const validationSchema = z.object({
  firstName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  lastName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  email: z.string().email(),
  phone: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  address: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  zip: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  city: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  companyName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  companyRole: z.string().min(1),
  marketing: z.union([z.string(), z.boolean()])
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const Profile = () => {
  const navigateTo = useNavigate()
  const { setShowDialog, user } = useBoundStore((state: StoreState) => ({
    setShowDialog: state.setShowDialog,
    user: state.user
  }))

  if (user === null) {
    navigateTo('/')
    return null
  }

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ...user,
      marketing: user?.marketing?.toString()
    }
  })

  const {
    handleSubmit,
    reset,
    formState: { isLoading, isDirty }
  } = form

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    data.marketing = data.marketing === 'true' ? true : false
    profileClient.post('update', data).then((res: AxiosResponse) => {
      if (res.data.status === true) {
        setShowDialog('InfoChangedDialog')
        data.marketing = data.marketing.toString()
        reset(data)
      }
    })
  }

  return (
    <>
      <h1 className="heading-l mb-6">{t('Min profil')}</h1>
      <p className="mb-6 max-w-[636px]">
        {t(
          'Nedan kan du se dina uppgifter kopplade till ditt konto. Om uppgifterna inte stämmer kan du uppdatera dem direkt i formuläret nedan.'
        )}
      </p>
      {isLoading ? <LoadingCart /> : null}
      {!isLoading ? (
        <>
          <FormProvider {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mb-10 grid grid-cols-2 flex-col gap-6"
            >
              <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
                {t('Personuppgifter')}
              </h3>
              <Input
                label={t('Förnamn') || ''}
                name="firstName"
                type="text"
                className="col-span-1"
                placeholder={t('Ditt förnamn') || ''}
              />
              <Input
                label={t('Efternamn') || ''}
                name="lastName"
                type="text"
                className="col-span-1"
                placeholder={t('Ditt efternamn') || ''}
              />
              <Input
                label={t('E-Postadress') || ''}
                name="email"
                type="email"
                placeholder="user@example.com"
                className="col-span-1"
                disabled
              />
              <Input
                label={t('Telefonnummer') || ''}
                name="phone"
                type="text"
                className="col-span-1"
              />
              <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
                {t('Adress')}
              </h3>
              <Input
                label={t('Gatuadress') || ''}
                name="address"
                type="text"
                placeholder={t('Din gatuadress') || ''}
                className="col-span-full"
              />
              <Input
                label={t('Postnummer') || ''}
                name="zip"
                type="text"
                placeholder={t('Postnummer') || ''}
                className="col-span-1"
              />
              <Input
                label={t('Postort') || ''}
                name="city"
                type="text"
                placeholder={t('Ort') || ''}
                className="col-span-1"
              />
              <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
                {t('Företag')}
              </h3>
              <Input
                label={t('Företag') || ''}
                name="companyName"
                type="text"
                placeholder={t('Namn på företag') || ''}
                className="col-span-1"
              />
              <Select
                label={t('Yrkesroll') || ''}
                name="companyRole"
                placeholder={t('Välj alternativ') || ''}
                className="col-span-1"
                options={[
                  {
                    value: t('Projektör') || '',
                    label: t('Projektör') || ''
                  },
                  { value: t('Montör') || '', label: t('Montör') || '' },
                  { value: t('Inköpare') || '', label: t('Inköpare') || '' },
                  { value: t('Säljare') || '', label: t('Säljare') || '' },
                  { value: t('Övrigt') || '', label: t('Övrigt') || '' }
                ]}
              />
              <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
                {t('Inställningar för marknadsföring')}
              </h3>
              <p>
                {t(
                  'Välj hur du vill ta emot marknadsföringsmeddelanden från oss.'
                )}
              </p>
              <BasicRadio
                name="marketing"
                options={[
                  {
                    value: true,
                    label: t('Ja, jag vill ta emot marknadsföring') || ''
                  },
                  {
                    value: false,
                    label: t('Nej, jag vill inte ta emot marknadsföring') || ''
                  }
                ]}
              />
              <div className="col-span-full mt-4 flex justify-end">
                <Button disabled={!isDirty}>{t('Uppdatera uppgifter')}</Button>
              </div>
            </form>
          </FormProvider>
          <hr className="mb-10" />
          <FormChangePassword />
        </>
      ) : null}
    </>
  )
}

export default Profile
