import { Dispatch, SetStateAction } from 'react'
import Modal from './Modal'
import { Trans, useTranslation } from 'react-i18next'
import { z } from 'zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Checkbox from './form/fields/Checkbox'
import Button from './buttons/Button'
import { useNavigate } from 'react-router-dom'

const validationSchema = z.object({
  confirm: z.boolean()
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  setShowGuestDialog: Dispatch<SetStateAction<boolean>>
}

const ConfirmGuestModal = ({ setShowGuestDialog }: Props) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema)
  })

  const { handleSubmit, watch } = form

  const onSubmit: SubmitHandler<ValidationSchema> = () => {
    sessionStorage.setItem('confirmedGuest', 'true')
    navigateTo('/')
  }

  const confirm = watch('confirm')

  return (
    <>
      <Modal handleClose={() => setShowGuestDialog(false)}>
        <h2 className="heading-l mb-6">{t('Innan du fortsätter som gäst')}</h2>
        <div className="mb-8">
          <Trans i18nKey="confirm_guest_modal">
            <p>
              Innan du går vidare som gästanvändare av Weland Ståls kalkylator
              för solpanelsfästen vill vi informera om några viktiga punkter:
            </p>
            <br />
            <ol className="list-outside list-decimal pl-[19px] text-base font-light">
              <li>
                Du kommer inte ha tillgång till beräkningsvärden, inklusive
                utnyttjandegrader, som är avgörande för en korrekt installation
                av det valda systemet.
              </li>
              <li>Priser för Weland Ståls produkter kommer inte att visas.</li>
              <li>
                Alla konfigurationer som du gör som gäst kommer endast att
                finnas lokalt på din enhet. Det finns ingen koppling till ett
                konto, vilket innebär att om du använder en annan enhet eller
                tar bort webbläsarhistoriken kan du förlora alla konfigurationer
                som gjorts.
              </li>
              <li>
                Om du har skapat konfigurationer utan att lägga till dem i
                projektkorgen kommer de att försvinna om du lämnar sidan eller
                stänger webbläsaren. Var noga med att lägga till önskade
                konfigurationer i varukorgen för att de ska sparas på din enhet.
              </li>
              <li>
                Konfigurationer skapade av gästanvändare kan varken återupptas
                eller justeras.
              </li>
              <li>
                Om konfigurationer tas bort från projektkorgen kommer de att gå
                förlorade.
              </li>
            </ol>
            <br />
            <p>
              Om du har frågor eller behöver hjälp är du välkommen att kontakta
              oss på <strong>0321-261 60</strong>.
            </p>
          </Trans>
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=""
          >
            <Checkbox
              name="confirm"
              label={t('Jag har läst och förstått informationen') || ''}
              className="mb-10"
            />
            <div className="flex justify-end">
              <Button
                variant="ghost"
                onClick={() => setShowGuestDialog(false)}
                className="mr-4"
              >
                {t('Avbryt')}
              </Button>
              <Button disabled={!confirm}>{t('Fortsätt som gäst')}</Button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default ConfirmGuestModal
