import { useTranslation } from 'react-i18next'
import SecondaryButton from '../buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '../../store'
import { shallow } from 'zustand/shallow'
import { cn } from '../../utils/tailwind'

const AddPanelArea = () => {
  const { t } = useTranslation()
  const {
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    isConfigurationComplete,
    showPanelSettings,
    setIsDrawing,
    setActiveArea
  } = useBoundStore(
    (state: StoreState) => ({
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.isRoofPropertiesDataValid,
      isConfigurationComplete: state.isConfigurationComplete,
      conditions: state.conditions,
      showPanelSettings: state.showPanelSettings,
      setIsDrawing: state.setIsDrawing,
      setActiveArea: state.setActiveArea
    }),
    shallow
  )

  if (
    !isPositionDataValid ||
    !isRoofMaterialDataValid ||
    !isRoofPropertiesDataValid
  ) {
    return
  }

  const handleClick = () => {
    setActiveArea(null)
    setIsDrawing(true)
    localStorage.setItem('highlightAddPanelAreaButton', 'false')
  }

  const highlightAddPanelAreaButton = localStorage.getItem(
    'highlightAddPanelAreaButton'
  )

  if (
    (showPanelSettings && highlightAddPanelAreaButton !== 'false') ||
    isConfigurationComplete ||
    (!isPositionDataValid &&
      !isRoofMaterialDataValid &&
      !isRoofPropertiesDataValid)
  ) {
    return
  }

  return (
    <SecondaryButton
      className={cn(
        'absolute right-[152px] top-14',
        highlightAddPanelAreaButton === null && 'animate-glow'
      )}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        className="mr-4"
        icon={faPlus}
      />
      {t('Panelyta')}
    </SecondaryButton>
  )
}

export default AddPanelArea
