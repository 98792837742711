import { StoreState, useBoundStore } from '../store'
import { useTranslation } from 'react-i18next'
import { cn } from '../utils/tailwind'
import { formatCurrency } from '../utils/utils'
import { getRoofName, round } from '../lib/utils'

const Summary = () => {
  const { t } = useTranslation()
  const {
    summary,
    conditions,
    roof,
    isConfigurationComplete,
    panelAreaSections
  } = useBoundStore((state: StoreState) => ({
    summary: state.summary,
    conditions: state.conditions,
    roof: state.roof,
    isConfigurationComplete: state.isConfigurationComplete,
    panelAreaSections: state.panelAreaSections
  }))

  if (!isConfigurationComplete) {
    return
  }

  return (
    <div className="px-10 py-14">
      <h3 className="heading-m mb-6">{t('Konfiguration')}</h3>
      <div className="mb-10 text-base">
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Antal paneler')}</div>
          </div>
          <div className="font-light">
            <div>{`${summary.numberOfPanels} ${t('st')}`}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Vikt infästning')}</div>
          </div>
          <div className="font-light">
            <div>{`${round(summary.totalMountingWeight / 1000, 1)} ${t(
              'kg'
            )}`}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Vikt paneler')}</div>
          </div>
          <div className="font-light">
            <div>{`${round(summary.totalPanelWeight / 1000, 1)} ${t(
              'kg'
            )}`}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Total vikt för systemet')}</div>
          </div>
          <div className="font-light">
            <div>{`${round((summary.totalWeight || 0) / 1000, 1)} ${t(
              'kg'
            )}`}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Total vikt per kvadratmeter')}</div>
          </div>
          <div className="font-light">
            <div>{`${round(
              (summary.totalWeightPerSquareMeter || 0) / 1000,
              1
            )} ${t('kg/m²')}`}</div>
          </div>
        </div>
        {summary.price ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Totalsumma (exkl. moms)')}</div>
            </div>
            <div>
              <div>
                <span
                  className={cn(
                    'font-bold',
                    summary.originalPrice != summary.price && 'text-red-100'
                  )}
                >
                  {formatCurrency(summary.price)}
                </span>
                {summary.originalPrice != summary.price ? (
                  <span className="ml-6 font-light line-through">{`${summary.originalPrice.toLocaleString(
                    'sv-SE'
                  )} ${t('SEK')}`}</span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <hr className="mb-10 bg-grayscale-25" />
      <h3 className="heading-m mb-6">{t('Projektinformation')}</h3>
      <div className="mb-10 text-base">
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Datum')}</div>
          </div>
          <div className="font-light">
            <div>{summary.projectDetails.date}</div>
          </div>
        </div>
        {conditions.address !== undefined ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Adress')}</div>
            </div>
            <div className="font-light">
              <div>{conditions.address}</div>
            </div>
          </div>
        ) : null}
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Dimensionering')}</div>
          </div>
          <div className="font-light">
            <div>{summary.projectDetails.dimensioning}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Konsekvensklass')}</div>
          </div>
          <div className="font-light">
            <div>{summary.projectDetails.consequenceClass}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('System')}</div>
          </div>
          <div className="font-light">
            <div>{summary.projectDetails.system}</div>
          </div>
        </div>
        {conditions.configurationSystem === 'low' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Systemets lutning')}</div>
            </div>
            <div className="font-light">
              <div>{`${summary.projectDetails.systemSlope}°`}</div>
            </div>
          </div>
        ) : null}
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Antal paneler för systemet')}</div>
          </div>
          <div className="font-light">
            <div>{summary.numberOfPanels}</div>
          </div>
        </div>
      </div>
      <hr className="mb-10 bg-grayscale-25" />
      <h3 className="heading-m mb-6">{t('Lastförutsättningar')}</h3>
      <div className="mb-10 text-base">
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Säkerhetsklass')}</div>
          </div>
          <div className="font-light">
            <div>{conditions.safetyClass}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Dimensionerande livslängd')}</div>
          </div>
          <div className="font-light">
            <div>
              {conditions.lifeSpan} {t('år')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Referensvindhastighet')}</div>
          </div>
          <div className="font-light">
            <div>
              {conditions.wind} {t('m/s')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Terrängtyp')}</div>
          </div>
          <div className="font-light">
            <div>{conditions.terrain}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Lastkombination faktor Ψ 0v')}</div>
          </div>
          <div className="font-light">
            <div>{summary.loadConditions.windCoefficient}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Snözon')}</div>
          </div>
          <div className="font-light">
            <div>
              {conditions.snow} {t('kN/m2')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Lastkombination faktor Ψ 0s')}</div>
          </div>
          <div className="font-light">
            <div>{summary.loadConditions.snowCoefficient}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Snörasskydd')}</div>
          </div>
          <div className="font-light">
            <div>{roof.snowGuard ? t('Ja') : t('Nej')}</div>
          </div>
        </div>
      </div>
      <hr className="mb-10 bg-grayscale-25" />
      <h3 className="heading-m mb-6">{t('Takinformation')}</h3>
      <div className="mb-10 text-base">
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Mått A')}</div>
          </div>
          <div className="font-light">
            <div>
              {round(roof.measurementA / 1000, 3)} {t('m')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Mått B')}</div>
          </div>
          <div className="font-light">
            <div>
              {round(roof.measurementB / 1000, 3)} {t('m')}
            </div>
          </div>
        </div>
        {roof.measurementC > 0 ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Mått C')}</div>
            </div>
            <div className="font-light">
              <div>
                {round(roof.measurementC / 1000, 3)} {t('m')}
              </div>
            </div>
          </div>
        ) : null}
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Byggnadens höjd')}</div>
          </div>
          <div className="font-light">
            <div>
              {round(roof.ridgeHeight / 1000, 3)} {t('m')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Taklutning')}</div>
          </div>
          <div className="font-light">
            <div>
              {roof.slope}
              {t('°')}
            </div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Taktyp')}</div>
          </div>
          <div className="font-light">
            <div>{getRoofName(roof.type)}</div>
          </div>
        </div>
        <div className="mb-2 flex">
          <div className="w-56 font-bold">
            <div>{t('Infästning')}</div>
          </div>
          <div className="font-light">
            <div>{summary.projectDetails.attachment}</div>
          </div>
        </div>
        {roof.covering === 'concrete_tile' || roof.covering === 'brick_tile' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Storlek panna')}</div>
            </div>
            <div className="font-light">
              <div>
                {`${roof.tileWidth} x ${roof.tileHeight}`} {t('mm')}
              </div>
            </div>
          </div>
        ) : null}
        {roof.covering === 'corrugated_tin_metal' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Avstånd mellan falsar')}</div>
            </div>
            <div className="font-light">
              <div>
                {roof.topDistance} {t('mm')}
              </div>
            </div>
          </div>
        ) : null}
        {roof.covering === 'profiled_sheet_metal' ||
        roof.covering === 'tile_profiled_sheet_metal' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Avstånd mellan toppar')}</div>
            </div>
            <div className="font-light">
              <div>
                {roof.topDistance} {t('mm')}
              </div>
            </div>
          </div>
        ) : null}
        {roof.attachment === 'lath_mount' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Dimension läkt')}</div>
            </div>
            <div className="font-light">
              <div>
                {roof.lathDimension} {t('mm')}
              </div>
            </div>
          </div>
        ) : null}
        {(roof.attachment === 'long_rail' ||
          roof.attachment === 'short_rail') &&
        panelAreaSections !== undefined ? (
          <>
            <div className="mb-2 flex">
              <div className="w-56 font-bold">
                <div>{t('Antal skruv/meter')}</div>
              </div>
              <div className="font-light">
                <div>
                  {t(
                    '{{numberOfScrews}} st ({{numberOfScrewPairs}} skruvpar)',
                    {
                      numberOfScrews:
                        (
                          panelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).hi.screwsPerMeter ?? 0,
                      numberOfScrewPairs:
                        ((
                          panelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).hi.screwsPerMeter ?? 0) / 2
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="mb-2 flex">
              <div className="w-56 font-bold">
                <div>{t('Antal skruv/meter (randzon)')}</div>
              </div>
              <div className="font-light">
                <div>
                  {t(
                    '{{numberOfScrews}} st ({{numberOfScrewPairs}} skruvpar)',
                    {
                      numberOfScrews:
                        (
                          panelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).fgj.screwsPerMeter ?? 0,
                      numberOfScrewPairs:
                        ((
                          panelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).fgj.screwsPerMeter ?? 0) / 2
                    }
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default Summary
