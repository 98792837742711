import { useTranslation } from 'react-i18next'
import { cn } from '../utils/tailwind'
import { formatCurrency } from '../utils/utils'

type Props = {
  products: Product[]
  variant?: 'cart'
}

const ProductList = ({ products, variant }: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      {variant !== 'cart' ? (
        <h2 className="mb-6 text-xl">{t('Artikelspecifikation')}</h2>
      ) : null}
      <div className="table w-full border-collapse">
        <div className="table-row text-base">
          <div className="table-cell pb-2 pr-5 font-bold">{t('Antal')}</div>
          <div className="table-cell pb-2 pr-5 font-bold">{t('Art. nr')}</div>
          <div className="table-cell pb-2 pr-5 font-bold">{t('Benämning')}</div>

          {products.length > 0 && products[0].price !== undefined ? (
            <div className="table-cell pb-2 pr-5 font-bold">{t('Pris/st')}</div>
          ) : null}
          {/* {products.length > 0 && products[0].discount !== undefined ? (
            <div className="table-cell pb-2 pr-5 font-bold">{t('Rabatt')}</div>
          ) : null} */}
          {products.length > 0 && products[0].totalPrice !== undefined ? (
            <div className="table-cell pb-2 font-bold">{t('Summa')}</div>
          ) : null}
        </div>
        {products.map((product, key) => (
          <div
            key={key}
            className="table-row border-b border-grayscale-25 text-base last:border-none"
          >
            <div
              className={cn(
                'table-cell pr-5',
                variant == 'cart' ? 'py-2' : 'py-4'
              )}
            >
              {product.quantity}
            </div>
            <div
              className={cn(
                'table-cell pr-5',
                variant == 'cart' ? 'py-2' : 'py-4'
              )}
            >
              {product.sku}
            </div>
            <div
              className={cn(
                'table-cell pr-5',
                variant == 'cart' ? 'py-2' : 'py-4'
              )}
            >
              {product.name}
            </div>
            {product.price !== undefined ? (
              <div
                className={cn(
                  'table-cell whitespace-nowrap pr-5',
                  variant == 'cart' ? 'py-2' : 'py-4',
                  product.originalPrice != product.price && 'text-red-100'
                )}
              >
                {formatCurrency(product.price)}
              </div>
            ) : null}
            {/* {product.discount !== undefined ? (
              <div
                className={cn(
                  'table-cell pr-5',
                  variant == 'cart' ? 'py-2' : 'py-4'
                )}
              >
                {product.discount !== null ? `${product.discount}%` : '-'}
              </div>
            ) : null} */}
            {product.totalPrice !== undefined ? (
              <div
                className={cn(
                  'table-cell whitespace-nowrap',
                  variant == 'cart' ? 'py-2' : 'py-4',
                  product.originalTotalPrice != product.totalPrice &&
                    'text-red-100'
                )}
              >
                {formatCurrency(product.totalPrice)}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductList
