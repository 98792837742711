import React from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import PanelAreaSection from './PanelAreaSection'
import PanelAreaSectionLabel from './PanelAreaSectionLabel'
import { getSectionLabelText } from '~/utils/configurator'

type Props = {
  imageView?: boolean
}

const PanelAreaSections = React.memo(({ imageView }: Props) => {
  const scale = 1
  const {
    panelAreas,
    panelAreaSections,
    sectionsOverviewImageView,
    roofImageScale
  } = useBoundStore(
    (state: StoreState) => ({
      panelAreaSections: state.panelAreaSections,
      panelAreas: state.panelAreas,
      activeArea: state.activeArea,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      roofImageScale: state.roofImageScale
    }),
    shallow
  )

  return (
    <>
      {panelAreaSections.map((panelAreaSection, index) => {
        return (
          <React.Fragment key={index}>
            <PanelAreaSection
              key={panelAreaSection.panelAreaUid}
              panelAreaSection={panelAreaSection}
              scale={scale}
              imageView={imageView}
            />
            {imageView === true && sectionsOverviewImageView === true ? (
              <PanelAreaSectionLabel
                key={`label-${panelAreaSection.panelAreaUid}`}
                uid={panelAreaSection.uid}
                position={panelAreaSection.position}
                size={panelAreaSection.size}
                labelText={getSectionLabelText(
                  panelAreaSection.uid,
                  panelAreaSection.panelAreaUid,
                  panelAreas,
                  panelAreaSections
                )}
                scale={roofImageScale}
              />
            ) : null}
          </React.Fragment>
        )
      })}
    </>
  )
})

export default PanelAreaSections
