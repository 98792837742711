import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { cn } from '../../utils/tailwind'
import { v4 as uuidv4 } from 'uuid'
import { Tooltip } from 'react-tooltip'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  toolTipText?: string
}

const RoundButton: React.FC<IButtonProps> = ({
  type,
  children,
  disabled,
  onClick,
  className,
  toolTipText
}) => {
  const uid = uuidv4()

  return (
    <>
      <button
        className={cn(
          'flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full text-xl text-grayscale-100',
          'hover:bg-silver-25',
          className
        )}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...(toolTipText !== undefined && {
          'data-tooltip-id': uid,
          'data-tooltip-content': toolTipText,
          'data-tooltip-place': 'right',
          'data-tooltip-delay-show': 500
        })}
      >
        {children}
      </button>
      {toolTipText !== undefined ? (
        <Tooltip
          id={uid}
          style={{ borderRadius: 0, padding: '12px 16px' }}
        />
      ) : null}
    </>
  )
}

export default RoundButton
