import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './fields/Input'
import Button from '../buttons/Button'
import Radio from './fields/Radio'
import {
  panelMountingOptions,
  shortRailPanelMountingOptions,
  corrugatedTinMetalPanelMountingOptions
} from './formOptions'
import Checkbox from './fields/Checkbox'
import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'

const validationSchema = z.object({
  system: z.string(),
  width: z.number(),
  height: z.number(),
  weight: z.number(),
  mounting: z.string(),
  useSupportPlates: z.boolean(),
  useThreeRails: z.boolean()
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const FormPanelSettings = React.memo(() => {
  const { t } = useTranslation()

  const {
    roof,
    activeArea,
    panelAreas,
    panelInfo,
    setPanelInfo,
    setShowPanelSettings,
    updatePanelArea
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      activeArea: state.activeArea,
      panelAreas: state.panelAreas,
      panelInfo: state.panelInfo,
      setPanelInfo: state.setPanelInfo,
      setShowPanelSettings: state.setShowPanelSettings,
      updatePanelArea: state.updatePanelArea
    }),
    shallow
  )

  const panelAreaIndex = panelAreas.findIndex(
    (panelArea) => panelArea.uid === activeArea
  )

  useEffect(() => {
    if (activeArea !== null && panelAreaIndex !== -1) {
      const panelInfo = panelAreas[panelAreaIndex].panelInfo
      reset(panelInfo)
    }
  }, [activeArea])

  const selectedArea = activeArea !== null

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      system: 'parallel',
      width: panelInfo.width,
      height: panelInfo.height,
      weight: panelInfo.weight,
      mounting: panelInfo.mounting,
      useSupportPlates: panelInfo.useSupportPlates,
      useThreeRails: panelInfo.useThreeRails
    }
  })

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty }
  } = form

  const width = watch('width')
  const height = watch('height')
  const mounting = watch('mounting')

  const [isPortrait, setIsPortrait] = useState(width < height)

  const calculatePanelWeight = (width: number, height: number) => {
    if (width > 0 && height > 0) {
      return Math.round((width / 1000) * (height / 1000) * 13)
    } else {
      return 0
    }
  }

  const [shouldCalculateWeight, setShouldCalculateWeight] = useState(false)

  useEffect(() => {
    if (shouldCalculateWeight) {
      setValue('weight', calculatePanelWeight(width, height))
    }
    setShouldCalculateWeight(true)
    const mountingStringArray = mounting.split('-')
    if (width > height) {
      if (mountingStringArray[1] === 'portrait') {
        mountingStringArray[1] = 'landscape'
        setValue('mounting', mountingStringArray.join('-'))
      }
    } else {
      if (mountingStringArray[1] !== 'portrait') {
        mountingStringArray[1] = 'portrait'
        setValue('mounting', mountingStringArray.join('-'))
      }
    }
    setIsPortrait(width < height)
  }, [width, height])

  useEffect(() => {
    if (
      ((mounting == '90-portrait' || mounting == '0-portrait') &&
        !isPortrait) ||
      ((mounting == '90-landscape' || mounting == '0-landscape') && isPortrait)
    ) {
      setShouldCalculateWeight(false)
      setValue('width', height)
      setValue('height', width)
    }
  }, [mounting])

  useEffect(() => {
    const mountingStringArray = mounting.split('-')
    if (
      roof.covering === 'corrugated_tin_metal' ||
      (roof.attachment === 'short_rail' && mountingStringArray[0] === '0')
    ) {
      mountingStringArray[0] = '90'
    }
    setValue('mounting', mountingStringArray.join('-'))
  }, [roof])

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    let validData = null
    try {
      validData = validationSchema.parse(data)
      const panelInfoData = {
        ...panelInfo,
        ...validData,
        widthMounted: validData.width,
        heightMounted: validData.height
      }
      if (activeArea !== null) {
        updatePanelArea({
          ...panelAreas[panelAreaIndex],
          panelInfo: panelInfoData,
          railsPerRow: validData.useThreeRails ? 3 : 2,
          useSupportPlates: validData.useSupportPlates
        })
      } else {
        setPanelInfo(panelInfoData)
        localStorage.setItem('panelWidth', validData.width.toString())
        localStorage.setItem('panelHeight', validData.height.toString())
        localStorage.setItem('panelWeight', validData.weight.toString())
        localStorage.setItem('panelMounting', validData.mounting.toString())
        localStorage.setItem(
          'useSupportPlates',
          validData.useSupportPlates.toString()
        )
        localStorage.setItem(
          'useThreeRails',
          validData.useThreeRails.toString()
        )
        localStorage.setItem('system', validData.system)
      }
      setShowPanelSettings(false)
      reset({
        height: validData.height,
        width: validData.width,
        weight: validData.weight,
        mounting: validData.mounting,
        useSupportPlates: validData.useSupportPlates,
        useThreeRails: validData.useThreeRails
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const getMountingOptions = () => {
    if (roof.attachment === 'short_rail') {
      return shortRailPanelMountingOptions
    }
    if (roof.covering === 'corrugated_tin_metal') {
      return corrugatedTinMetalPanelMountingOptions
    }
    return panelMountingOptions
  }

  return (
    <FormProvider {...form}>
      <form
        className="mb-6 grid w-full grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="heading-m col-span-full mb-6">
          {panelAreaIndex > -1
            ? `${t('Redigera inställningar - Panelyta')} ${panelAreaIndex + 1}`
            : t('Panelinställningar')}
        </h2>
        <h3 className="col-span-full text-lg font-bold">{t('Dimensioner')}</h3>
        <Input
          name="system"
          type="hidden"
        />
        <Input
          name="width"
          type="number"
          label={t('Bredd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
        />
        <Input
          name="height"
          type="number"
          label={t('Höjd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
        />
        <Input
          name="weight"
          type="number"
          label={t('Vikt') || ''}
          className="col-span-1 mb-4"
          required
          disabled={selectedArea}
        />
        <h3 className="col-span-full text-lg font-bold">
          {t('Panelmontering')}
        </h3>
        <Radio
          name="mounting"
          options={getMountingOptions()}
          vertical
          disabled={selectedArea}
        />
        <h3 className="col-span-full text-lg font-bold">
          {t('Tillval')} <span className="font-normal">{t('(valfritt)')}</span>
        </h3>
        {roof.covering == 'flat' &&
        ['sealing_plate_flat', 'sealing_plate_perforated'].includes(
          roof.attachment
        ) ? (
          <Checkbox
            name="useSupportPlates"
            label={t('Använd stödplattor')}
            className=""
          />
        ) : null}
        <Checkbox
          name="useThreeRails"
          label={t('Monteras med 3 skenor')}
          className="mb-6"
        />
        <div className="col-start-2 flex justify-end">
          <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default FormPanelSettings!
