import { faXmark } from '@fortawesome/sharp-regular-svg-icons'
import RoundButton from './buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import { saveConfiguration } from '../lib/utils'
import { v4 as uuidv4 } from 'uuid'
import React, { useEffect } from 'react'
import { AxiosResponse } from 'axios'

const CloseConfigurator = React.memo(() => {
  const navigateTo = useNavigate()

  const {
    user,
    reference,
    isConfigurationComplete,
    isEdited,
    conditions,
    setShowDialog,
    setDialogActions,
    setDisabledDialogActions,
    setUid,
    setName,
    setReference,
    setIsEdited
  } = useBoundStore(
    (state: StoreState) => ({
      localCart: state.localCart,
      user: state.user,
      reference: state.reference,
      isConfigurationComplete: state.isConfigurationComplete,
      isEdited: state.isEdited,
      conditions: state.conditions,
      setShowDialog: state.setShowDialog,
      setDialogActions: state.setDialogActions,
      setDisabledDialogActions: state.setDisabledDialogActions,
      setUid: state.setUid,
      setName: state.setName,
      setReference: state.setReference,
      setIsEdited: state.setIsEdited
    }),
    shallow
  )

  const handleClose = () => {
    if (reference === undefined && !isConfigurationComplete) {
      setShowDialog('ConfirmCloseDialog')
      setDialogActions({ closeConfigurator })
    }
    if (reference === undefined && isConfigurationComplete && user) {
      setShowDialog('SaveConfigurationDialog')
      setDialogActions({ save, closeWithoutSave })
    }
    if (reference !== undefined && isConfigurationComplete && user) {
      setShowDialog('ConfirmSavedCloseDialog')
      setDialogActions({ closeConfigurator })
    }
    if (reference !== undefined && isEdited && user) {
      setShowDialog('SaveChangesDialog')
      setDialogActions({ NoSave: closeConfigurator, Save: HandleSaveChanges })
    }
    if (user === null) {
      setShowDialog('ConfirmCloseDialog')
      setDialogActions({ closeConfigurator })
    }
    if (user === null) {
      setShowDialog('ConfirmCloseDialog')
      setDialogActions({ closeConfigurator })
    }
  }

  const handleSaveResponse = (res: AxiosResponse) => {
    setReference(res.data.reference)
    setIsEdited(false)
    setShowDialog('SaveSuccessDialog')
    setDialogActions({ closeConfigurator })
  }

  const save = () => {
    saveConfiguration(handleSaveResponse)
  }

  const handleSaveChangesResponse = (res: AxiosResponse) => {
    setReference(res.data.reference)
    setIsEdited(false)
    setShowDialog('SaveChangesSuccessCloseDialog')
    setDialogActions({ closeConfigurator })
  }

  const saveChanges = () => {
    saveConfiguration(handleSaveChangesResponse)
  }

  const closeWithoutSave = () => {
    closeConfigurator()
  }

  const HandleSaveChanges = () => {
    setShowDialog('SaveLoadedDialog')
    setDialogActions({
      SaveNew: saveNew,
      Save: saveChanges
    })
  }

  const handleSaveNewResponse = (res: AxiosResponse) => {
    setReference(res.data.reference)
    setIsEdited(false)
    setShowDialog('SaveChangesAsNewSuccessCloseDialog')
    setDialogActions({ closeConfigurator })
  }

  const saveNew = async () => {
    setDisabledDialogActions({
      saveNew: true,
      save: true
    })
    await setName(conditions.name + ' kopia')
    await setUid(uuidv4())
    saveConfiguration(handleSaveNewResponse)
  }

  const closeConfigurator = () => {
    navigateTo('/')
    setShowDialog(false)
  }

  useEffect(() => {
    if (isEdited) {
      setDisabledDialogActions({
        saveNew: false,
        save: false
      })
    }
  }, [isEdited])

  return (
    <RoundButton
      onClick={() => handleClose()}
      className="absolute right-14 top-14 z-50 bg-white"
    >
      <FontAwesomeIcon icon={faXmark} />
    </RoundButton>
  )
})

export default CloseConfigurator
