import { scaleMmToPixels, scalePixelsToMm } from './configurator'
import Konva from 'konva'

/**
 * Retrieves an image from a Konva stage and returns it as a data URL.
 *
 * @param stageRef - The reference to the Konva stage.
 * @param position - The position of the image on the stage.
 * @param size - The size of the image.
 * @param pixelRatio - The pixel ratio of the image. Defaults to 2 if not provided.
 * @returns The image as a data URL.
 */
export const getImage = (
  stageRef: Konva.Stage,
  position: Position,
  size: Size,
  pixelRatio: number
) => {
  pixelRatio = typeof pixelRatio !== 'undefined' ? pixelRatio : 2

  const scaledPosition = scaleMmToPixels<Position>(position)
  const scaledSize = scaleMmToPixels<Size>(size)

  return stageRef.getStage().toDataURL({
    x: scaledPosition.x,
    y: scaledPosition.y,
    width: scaledSize.width,
    height: scaledSize.height,
    pixelRatio: pixelRatio
  })
}

/**
 * Calculates the number of panels in the X direction based on the panel area section and panel orientation.
 * @param panelAreaSection - The panel area section object.
 * @param panelOrientation - The panel orientation, either 'horizontal' or 'vertical'.
 * @returns The number of panels in the X direction.
 */
const getNumberOfPanelsX = (
  panelAreaSection: PanelAreaSection,
  panelOrientation: 'horizontal' | 'vertical'
) => {
  const columns = panelAreaSection.columns

  if (columns > 5 && panelOrientation === 'horizontal') {
    return 2.5
  }
  return columns > 6 ? 3.5 : columns
}

/**
 * Calculates the number of panels in the Y direction based on the panel area section and panel orientation.
 * @param panelAreaSection - The panel area section object.
 * @param panelOrientation - The panel orientation, either 'horizontal' or 'vertical'.
 * @returns The number of panels in the Y direction.
 */
const getNumberOfPanelsY = (
  panelAreaSection: PanelAreaSection,
  panelOrientation: 'horizontal' | 'vertical'
) => {
  const rows = panelAreaSection.rows

  if (rows > 3 && panelOrientation === 'vertical') {
    return 1.5
  }
  return rows > 4 ? 2 : rows
}

// /**
//  * Calculates the length of the rail for a given panel area section and rail angle.
//  * @param panelAreaSection - The panel area section object.
//  * @param railAngle - The angle of the rail.
//  * @returns The length of the rail.
//  */
// const getPanelAreaSectionRailLength = (
//   panelAreaSection: PanelAreaSection,
//   railAngle: number
// ) => {
//   const rail = panelAreaSection.result.rails[0]
//   const railStartPosition = rail.startPosition
//   const railEndPosition = rail.endPosition

//   return railAngle === 0
//     ? railEndPosition.y - railStartPosition.y
//     : railEndPosition.x - railStartPosition.x
// }

/**
 * Calculates the padding for a panel area section based on various parameters.
 *
 * @param panelAreaSection - The panel area section object.
 * @param attachment - The attachment type.
 * @param railLength - The length of the rail.
 * @param railAngle - The angle of the rail.
 * @param panelAreaSystem - The panel area system.
 * @returns The calculated padding for the panel area section.
 */
const getPanelAreaSectionPadding = (
  panelAreaSection: PanelAreaSection,
  attachment: string,
  railLength: number,
  railAngle: number,
  panelAreaSystem: string
) => {
  if (attachment === 'short_rail') {
    return 175
  }

  let sectionPadding =
    railAngle === 0
      ? (railLength - panelAreaSection.size.height) / 2
      : (railLength - panelAreaSection.size.width) / 2

  const edgeDistance = panelAreaSection.result.edgeDistance

  if (
    edgeDistance <= 160 &&
    sectionPadding <= 160 &&
    panelAreaSystem === 'east/west'
  ) {
    return sectionPadding + 160 - edgeDistance
  } else if (panelAreaSystem === 'south') {
    return sectionPadding + 80 - Math.min(edgeDistance, 80)
  }

  return sectionPadding
}

/**
 * Calculates the total width of a solar panel system based on the number of panels,
 * panel information, padding, and system type.
 *
 * @param numberOfPanelsX - The number of panels in the x-axis.
 * @param panelInfo - The information about the panel.
 * @param paddingX - The padding in the x-axis.
 * @param system - The type of solar panel system.
 * @returns The total width of the solar panel system.
 */
const getWidth = (
  numberOfPanelsX: number,
  panelInfo: PanelInfo | PanelInfoLow,
  paddingX: number,
  system: string
) => {
  if (system === 'east/west') {
    const { gapRow, gapTop } = panelInfo as PanelInfoLow
    return (
      numberOfPanelsX * panelInfo.widthMounted +
      (numberOfPanelsX / 2 - 1) * gapRow +
      (numberOfPanelsX / 2) * gapTop +
      paddingX +
      60 // 60 is the width of half a mount + stroke width or something...
    )
  }

  return (
    numberOfPanelsX * (panelInfo.widthMounted + panelInfo.gap) -
    panelInfo.gap +
    paddingX * 2
  )
}

/**
 * Calculates the total height of a panel section based on the number of panels,
 * panel information, padding, and system type.
 *
 * @param numberOfPanelsY - The number of panels in the Y direction.
 * @param panelInfo - The information about the panel.
 * @param paddingY - The padding in the Y direction.
 * @param system - The type of system.
 * @returns The total height of the solar panel configuration.
 */
const getHeight = (
  numberOfPanelsY: number,
  panelInfo: PanelInfo | PanelInfoLow,
  paddingY: number,
  system: string
) => {
  const gap =
    system === 'south' ? (panelInfo as PanelInfoLow).gapRow : panelInfo.gap

  return (
    numberOfPanelsY * (panelInfo.heightMounted + gap) -
    gap +
    (numberOfPanelsY - 1) * 2 +
    paddingY * 2
  )
}

/**
 * Creates panel section images data.
 * @param stage - The Konva.Stage object.
 * @param panelAreaSections - An array of PanelAreaSection objects.
 * @param roofPosition - The position of the roof.
 * @param attachment - The attachment type.
 * @param panelAreas - An array of PanelArea objects.
 * @param scale - The scale factor.
 * @returns An array of SectionImageData objects.
 */
export const createPanelSectionImagesData = (
  stage: Konva.Stage,
  panelAreaSections: PanelAreaSection[],
  roofPosition: Position,
  attachment: string,
  panelAreas: PanelArea[],
  scale: number
) => {
  const sectionsImageData: SectionImageData[] = []

  panelAreaSections.forEach((panelAreaSection) => {
    const panelArea = panelAreas.find(
      (panelArea) => panelArea.uid === panelAreaSection.panelAreaUid
    )

    if (panelArea === undefined) {
      return
    }

    const { panelInfo, railAngle, system } = panelArea
    const panelSectionImageData: SectionImageData = {
      uid: panelAreaSection.uid,
      images: [],
      imageSize: {
        width: 0,
        height: 0
      },
      size: {
        width: 0,
        height: 0
      },
      split: {
        horizontal: false,
        vertical: false
      },
      attachment,
      railAngle,
      edgeDistance: panelAreaSection.result.edgeDistance
    }

    const panelOrientation =
      panelInfo.width > panelInfo.height ? 'horizontal' : 'vertical'

    const numberOfPanelsX = getNumberOfPanelsX(
      panelAreaSection,
      panelOrientation
    )
    const numberOfPanelsY = getNumberOfPanelsY(
      panelAreaSection,
      panelOrientation
    )

    panelSectionImageData.split.horizontal =
      numberOfPanelsX !== panelAreaSection.columns
    panelSectionImageData.split.vertical =
      numberOfPanelsY !== panelAreaSection.rows

    const railLength = panelAreaSection.result.railLength

    const sectionPadding = getPanelAreaSectionPadding(
      panelAreaSection,
      attachment,
      railLength,
      railAngle,
      panelArea.system
    )

    const [paddingX, paddingY] =
      railAngle == 0 ? [0, sectionPadding] : [sectionPadding, 0]

    const width = getWidth(numberOfPanelsX, panelInfo, paddingX, system)
    const height = getHeight(numberOfPanelsY, panelInfo, paddingY, system)

    const scaledRoofPosition = scalePixelsToMm<Position>(roofPosition)

    const positionX =
      scaledRoofPosition.x + (panelAreaSection.position.x - paddingX)
    const positionY =
      scaledRoofPosition.y + (panelAreaSection.position.y - paddingY)
    const position = {
      x: scaledRoofPosition.x + (panelAreaSection.position.x - paddingX + 20), // 20 is unexplainable position offset problem...
      y: scaledRoofPosition.y + (panelAreaSection.position.y - paddingY + 20)
    }

    const positionMaxX =
      positionX + (panelAreaSection.size.width + paddingX * 2)
    const positionMaxY =
      positionY + (panelAreaSection.size.height + paddingY * 2)
    const pixelRatio = 2 / scale
    const images = []

    images.push({
      image: getImage(stage, position, { width, height }, pixelRatio),
      position: {
        x: 0,
        y: 0
      },
      size: {
        width: width,
        height: height
      }
    })

    if (numberOfPanelsX != panelAreaSection.columns) {
      images.push({
        image: getImage(
          stage,
          { x: positionMaxX - width, y: positionY },
          { width, height },
          pixelRatio
        ),
        position: {
          x: width,
          y: 0
        },
        size: {
          width: width,
          height: height
        }
      })
    }

    if (numberOfPanelsY != panelAreaSection.rows) {
      images.push({
        image: getImage(
          stage,
          { x: positionX, y: positionMaxY - height },
          { width, height },
          pixelRatio
        ),
        position: {
          x: 0,
          y: height
        },
        size: {
          width: width,
          height: height
        }
      })
    }

    if (
      numberOfPanelsX != panelAreaSection.columns &&
      numberOfPanelsY != panelAreaSection.rows
    ) {
      images.push({
        image: getImage(
          stage,
          { x: positionMaxX - width, y: positionMaxY - height },
          { width, height },
          pixelRatio
        ),
        position: {
          x: width,
          y: height
        },
        size: {
          width: width,
          height: height
        }
      })
    }

    panelSectionImageData.imageSize = {
      height: height,
      width: width
    }

    panelSectionImageData.size =
      railAngle === 0
        ? { height: railLength, width: panelAreaSection.size.width }
        : { height: panelAreaSection.size.height, width: railLength }

    panelSectionImageData.railAngle = railAngle
    panelSectionImageData.images = images
    panelSectionImageData.uid = panelAreaSection.uid
    sectionsImageData.push(panelSectionImageData)
  })

  return sectionsImageData
}
