import { HTMLAttributes } from 'react'
import { cn } from '../utils/tailwind'

interface Props extends HTMLAttributes<HTMLDivElement> {
  progress: number
}

const ProgressBar = ({ progress, className }: Props) => {
  return (
    <div className={cn('mb-2 h-1 w-full bg-silver-50', className)}>
      <div
        className="h-full bg-primary"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
