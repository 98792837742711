import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLayerGroup,
  faRightFromBracket,
  faRightToBracket,
  faUser
} from '@fortawesome/sharp-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { cn } from '../utils/tailwind'
import { authClient } from '../http/api'
import newConfiguration from '../assets/new_configuration.svg'
import { StoreState, useBoundStore } from '../store'

const SideBar = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { user, setUser } = useBoundStore((store: StoreState) => ({
    user: store.user,
    setUser: store.setUser
  }))

  const logout = () => {
    authClient.post('/logout').then(() => {
      setUser(null)
      navigateTo('/login')
    })
  }

  return (
    <div className="inset-y fixed left-0 flex h-full w-64 flex-col justify-between bg-silver-25">
      <div className="mt-14">
        <NavLink
          to="/"
          className={({ isActive }) =>
            cn(
              'flex w-full flex-1 items-center py-4 pl-12 no-underline',
              isActive && 'bg-silver-50'
            )
          }
        >
          <img
            src={newConfiguration}
            className="mr-6 block h-6"
          />

          {t('Konfigurator')}
        </NavLink>
        <NavLink
          to="/cart"
          className={({ isActive }) =>
            cn(
              'flex w-full flex-1 items-center py-4 pl-12 no-underline',
              isActive && 'bg-silver-50'
            )
          }
        >
          <FontAwesomeIcon
            icon={faLayerGroup}
            fixedWidth
            className="mr-6 text-xl"
          />
          {t('Projektkorg')}
        </NavLink>
        {user ? (
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              cn(
                'flex w-full flex-1 items-center py-4 pl-12 no-underline',
                isActive && 'bg-silver-50'
              )
            }
          >
            <FontAwesomeIcon
              icon={faUser}
              fixedWidth
              className="mr-6 text-xl"
            />
            {t('Min profil')}
          </NavLink>
        ) : null}
      </div>
      <div className="mb-14 mt-auto">
        {user ? (
          <Link
            to=""
            className={cn(
              'flex w-full flex-1 items-center py-4 pl-12 no-underline'
            )}
            onClick={logout}
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              fixedWidth
              className="mr-6 text-xl"
            />
            {t('Logga ut')}
          </Link>
        ) : (
          <Link
            to="/login"
            className={cn(
              'flex w-full flex-1 items-center py-4 pl-12 no-underline'
            )}
          >
            <FontAwesomeIcon
              icon={faRightToBracket}
              fixedWidth
              className="mr-6 text-xl"
            />
            {t('Logga in')}
          </Link>
        )}
      </div>
    </div>
  )
}

export default SideBar
