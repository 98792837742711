import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/sharp-solid-svg-icons'
import { cn } from '../../utils/tailwind'
import { useTranslation } from 'react-i18next'

type Props = {
  step?: string
  title: string
  children: ReactNode
  className?: string
  isOpen: boolean
  isValid: boolean
  toggleOpen?: () => void
  disabled?: boolean
}

const Section = ({
  title,
  step,
  children,
  className,
  isOpen,
  isValid,
  toggleOpen,
  disabled
}: Props) => {
  const { t } = useTranslation()
  return (
    <div
      className={cn(
        'col-span-full w-full',
        className,
        disabled && 'text-grayscale-25'
      )}
    >
      <div
        onClick={toggleOpen}
        className="flex w-full cursor-pointer items-center"
      >
        {step !== undefined ? (
          isValid ? (
            <FontAwesomeIcon
              className="mr-6 text-primary"
              size="xl"
              icon={faCheckCircle}
            />
          ) : (
            <div
              className={cn(
                'mr-6 h-6 w-6 rounded-full border-2 border-primary text-center text-base font-extrabold leading-[22px]',
                disabled && 'border-grayscale-25'
              )}
            >
              {step}
            </div>
          )
        ) : null}
        <div className="text-xl">{title}</div>
        {toggleOpen !== undefined && disabled !== true ? (
          <button
            onClick={toggleOpen}
            className="ml-auto font-light underline"
          >
            {isOpen ? t('Avbryt') : t('Ändra')}
          </button>
        ) : null}
      </div>
      <div
        className={cn(
          'grid h-0 grid-cols-4 gap-4 overflow-hidden',
          isOpen && 'h-auto overflow-visible pb-4'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
