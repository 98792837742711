import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import ResultsTabs from './ResultsTabs'
import FormSave from './form/FormSave'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faLayerGroup
} from '@fortawesome/sharp-regular-svg-icons'
import BackButton from './buttons/BackButton'
import { useTranslation } from 'react-i18next'
import { cn } from '../utils/tailwind'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import RoundButton from './buttons/RoundButton'
import LoadingCart from './cart/LoadingCart'
import { saveConfiguration } from '../lib/utils'
import { v4 as uuidv4 } from 'uuid'
import { solarPanelClient } from '../http/api'

const Results = () => {
  const { t } = useTranslation()
  const [showPlan, setShowPlan] = useState(false)

  const {
    reference,
    showResults,
    isLoadingResults,
    panelAreaSections,
    isLoaded,
    isEdited,
    conditions,
    user,
    setShowResults,
    setIsComplete,
    setIsConfigurationComplete,
    setPanelAreaSections,
    setIsEdited,
    setShowDialog,
    setDialogActions,
    setDisabledDialogActions,
    setUid,
    setName,
    getCartCount,
    localCart
  } = useBoundStore(
    (state: StoreState) => ({
      reference: state.reference,
      showResults: state.showResults,
      isLoadingResults: state.isLoadingResults,
      panelAreaSections: state.panelAreaSections,
      isLoaded: state.isLoaded,
      isEdited: state.isEdited,
      conditions: state.conditions,
      user: state.user,
      localCart: state.localCart,
      updatePanelAreaSections: state.updatePanelAreaSection,
      setShowResults: state.setShowResults,
      setIsComplete: state.setIsComplete,
      setIsConfigurationComplete: state.setIsConfigurationComplete,
      setPanelAreaSections: state.setPanelAreaSections,
      setIsEdited: state.setIsEdited,
      setShowDialog: state.setShowDialog,
      setDialogActions: state.setDialogActions,
      setDisabledDialogActions: state.setDisabledDialogActions,
      setUid: state.setUid,
      setName: state.setName,
      getCartCount: state.getCartCount
    }),
    shallow
  )

  const handleAdjustConfiguration = () => {
    setIsComplete(false)
    setIsConfigurationComplete(false)
    setIsEdited(true)
    setPanelAreaSections([])
    if (showResults) {
      setShowResults(false)
    }
  }

  const navigateTo = useNavigate()

  const isPanelAreaSectionsImagesGenerated = () =>
    !panelAreaSections.some(
      (panelAreaSection) => panelAreaSection.image !== undefined
    )

  const referenceExists = (
    localCart: { [key: string]: { reference: string; quantity: number } },
    referenceValue: string
  ): boolean => {
    for (let key in localCart) {
      if (localCart[key].reference === referenceValue) {
        return true
      }
    }
    return false
  }

  const handleCartNavigate = () => {
    if (reference === undefined && user) {
      setShowDialog('SaveConfigurationDialog')
      setDialogActions({ save, closeWithoutSave })
    }
    if (reference !== undefined && !isEdited && user) {
      setShowDialog('ConfirmSavedCloseDialog')
      setDialogActions({ closeConfigurator })
    }
    if (reference !== undefined && isEdited && user) {
      setShowDialog('SaveChangesDialog')
      setDialogActions({ Save: saveChanges, NoSave: closeConfigurator })
    }
    if ((user === null && reference === undefined) || isEdited) {
      setShowDialog('ConfirmCloseDialog')
      setDialogActions({ closeConfigurator })
    }
    if (
      user === null &&
      reference !== undefined &&
      referenceExists(localCart, reference) &&
      !isEdited
    ) {
      navigateTo('/cart')
    }

    if (
      user === null &&
      reference !== undefined &&
      !referenceExists(localCart, reference)
    ) {
      setShowDialog('ConfirmCloseDialog')
      setDialogActions({ closeConfigurator })
    }
  }

  const handleSaveResponse = () => {
    setIsEdited(false)
    setShowDialog('SaveSuccessCartDialog')
    setDialogActions({ closeConfigurator })
  }

  const save = () => {
    saveConfiguration(handleSaveResponse)
  }

  const saveNew = async () => {
    setDisabledDialogActions({
      saveNew: true,
      save: true
    })
    await setName(conditions.name + ' kopia')
    await setUid(uuidv4())
    saveConfiguration(handleSaveResponse)
  }

  const saveChanges = () => {
    setShowDialog('SaveLoadedDialog')
    setDialogActions({
      SaveNew: saveNew,
      Save: save
    })
  }

  const closeWithoutSave = () => {
    closeConfigurator()
  }

  const closeConfigurator = () => {
    navigateTo('/cart')
    setShowDialog(false)
  }

  const [cartCount, setCartCount] = useState(0)

  const updateCartCount = () => {
    if (user) {
      solarPanelClient.get('/cart/quantity').then((res) => {
        setCartCount(res.data.quantity)
      })
    } else {
      setCartCount(getCartCount())
    }
  }

  useEffect(() => {
    updateCartCount()
  }, [localCart])

  return (
    <div
      className={cn(
        'absolute bottom-0 left-[-896px] top-0 z-20 flex w-full max-w-4xl flex-col bg-white px-14 py-[72px] shadow-[0_1px_4px_0_rgba(0,0,0,0.25)] transition-all duration-300',
        showResults && !showPlan && 'left-0'
      )}
    >
      {(isPanelAreaSectionsImagesGenerated() && !isLoaded) ||
      isLoadingResults ? (
        <>
          <LoadingCart />
        </>
      ) : (
        <>
          <div className="mb-14 flex justify-between">
            <BackButton onClick={handleAdjustConfiguration}>
              {t('Justera konfiguration')}
            </BackButton>
            <RoundButton
              className="relative"
              onClick={() => handleCartNavigate()}
              toolTipText={t('Projektkorg') || ''}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                fixedWidth
                className="text-xl"
              />
              {cartCount > 0 ? (
                <span className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-100 text-[10px] font-semibold text-white">
                  {cartCount}
                </span>
              ) : null}
            </RoundButton>
          </div>
          <ResultsTabs />
          <div className="flex">
            <FormSave handleCartUpdate={updateCartCount} />
          </div>
          {showResults ? (
            <div
              onClick={() => setShowPlan(!showPlan)}
              className="custom-clip-path absolute bottom-1/2 right-[-32px] flex h-24 w-8 translate-y-1/2 cursor-pointer items-center justify-center bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.25)] "
            >
              {showPlan ? (
                <FontAwesomeIcon icon={faChevronRight} />
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default Results
