import { StateCreator } from 'zustand'

export interface HistoryConfiguration {
  name: string
  reference: string
  pdfUrl: string
  updatedAt: string
}

interface History {
  configurations: HistoryConfiguration[]
  page: number
  total: number
  loaded: number
}

export interface HistorySlice {
  history: History
  setHistory: (history: History) => void
  addHistoryConfigurations: (
    configurations: HistoryConfiguration[],
    total: number,
    loaded: number,
    page: number
  ) => void
}

export const createHistorySlice: StateCreator<HistorySlice> = (set) => ({
  history: {
    configurations: [],
    page: 1,
    total: 0,
    loaded: 0
  },
  setHistory: (history: History) => set(() => ({ history })),
  addHistoryConfigurations: (
    configurations: HistoryConfiguration[],
    total: number,
    loaded: number,
    page: number
  ) =>
    set((state) => ({
      history: {
        configurations: [...state.history.configurations, ...configurations],
        total,
        loaded,
        page
      }
    }))
})
