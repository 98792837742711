import React, { useEffect, useRef, useState } from 'react'
import SectionImageStage from './SectionImageStage'
import Konva from 'konva'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { getImage } from '~/utils/images'
import { scalePixelsToMm } from '~/utils/configurator'

const SectionImageHandler = React.memo(() => {
  const {
    system,
    runImageCreation,
    panelAreaSections,
    panelSectionImagesData,
    updatePanelAreaSection
  } = useBoundStore(
    (state: StoreState) => ({
      system: state.conditions.configurationSystem,
      runImageCreation: state.runImageCreation,
      panelAreaSections: state.panelAreaSections,
      panelSectionImagesData: state.panelSectionImagesData,
      updatePanelAreaSection: state.updatePanelAreaSection
    }),
    shallow
  )

  const elementRefs = useRef<Konva.Stage[]>([])
  const setRef = (element: Konva.Stage, index: number) => {
    elementRefs.current[index] = element
  }

  const [imagesData, setImagesData] = useState<SectionImageData[]>([])

  useEffect(() => {
    elementRefs.current = []
    setImagesData(panelSectionImagesData)
  }, [panelSectionImagesData])

  useEffect(() => {
    if (runImageCreation) {
      elementRefs.current.forEach((stage) => {
        const size = scalePixelsToMm<Size>(stage.size())
        const image = getImage(stage, { x: 0, y: 0 }, size, 1)
        const panelAreaSection = panelAreaSections.find(
          (panelAreaSection) => panelAreaSection.uid === stage.id()
        )
        if (panelAreaSection) {
          updatePanelAreaSection({
            ...panelAreaSection,
            image
          })
        }
      })
    }
  }, [runImageCreation, elementRefs])

  return (
    <>
      {imagesData.map((panelSectionImageData, index) => (
        <SectionImageStage
          system={system ?? 'parallel'}
          setRef={setRef}
          index={index}
          key={panelSectionImageData.uid}
          imageSection={panelSectionImageData}
        />
      ))}
    </>
  )
})

export default SectionImageHandler
