import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import CartTabs from '../CartTabs'
import { StoreState, useBoundStore } from '../../store'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const HistoryPage = () => {
  const navigateTo = useNavigate()

  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))

  useEffect(() => {
    if (user === null) {
      navigateTo('/login')
    }
  }, [])

  return (
    <PageWrapper hideBackButton>
      <SideBar />
      <div className="h-full w-full max-w-[1432px] px-72 py-24">
        <CartTabs selectedTab={1} />
      </div>
    </PageWrapper>
  )
}

export default HistoryPage
