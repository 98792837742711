import { useTranslation } from 'react-i18next'
import { cn } from '../utils/tailwind'
import Cart from './cart/Cart'
import History from './History'
import { StoreState, useBoundStore } from '../store'
import { useNavigate } from 'react-router-dom'

interface Props {
  selectedTab: number
}

const CartTabs = ({ selectedTab }: Props) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))

  if (user === null) {
    return (
      <div className="mb-10 flex h-full w-full flex-col">
        <h1 className="heading-l mb-6">{t('Projektkorg')}</h1>
        <div className="h-full text-left">
          <Cart />
        </div>
      </div>
    )
  }

  return (
    <div className="mb-10 flex h-full w-full flex-col">
      <div className="mb-10 flex">
        <div
          className={cn(
            'w-1/2 cursor-pointer pb-5 text-center text-xl',
            selectedTab === 0
              ? 'border-b-4 border-grayscale-75 font-bold'
              : 'border-b border-grayscale-25'
          )}
          onClick={() => {
            navigateTo('/cart')
          }}
        >
          {t('Projektkorg')}
        </div>
        <div
          className={cn(
            'w-1/2 cursor-pointer pb-5 text-center text-xl',
            selectedTab === 1
              ? 'border-b-4 border-grayscale-75 font-bold'
              : 'border-b border-grayscale-25'
          )}
          onClick={() => {
            navigateTo('/history')
          }}
        >
          {t('Historik')}
        </div>
      </div>
      <div className="h-full text-left">
        {selectedTab === 0 ? <Cart /> : null}
        {selectedTab === 1 ? <History /> : null}
      </div>
    </div>
  )
}

export default CartTabs
