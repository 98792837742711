import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/sharp-solid-svg-icons'
import { Tooltip } from 'react-tooltip'

type Props = {
  id: string
  text: string
}

const Info = ({ id, text }: Props) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="px-2"
        data-tooltip-id={id}
      />
      <Tooltip
        id={id}
        content={text}
        style={{
          maxWidth: '178px',
          fontWeight: '300',
          fontSize: '14px',
          padding: '16px',
          borderRadius: '0'
        }}
      />
    </>
  )
}

export default Info
