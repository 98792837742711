import { useTranslation } from 'react-i18next'
import DialogBox from './DialogBox'
import { StoreState, useBoundStore } from '../../store'
import { shallow } from 'zustand/shallow'
import { useEffect } from 'react'
import LoadingCart from '../cart/LoadingCart'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const LoadingConfigurationDialog = ({ handleClose }: Props) => {
  const { t } = useTranslation()
  const { isConfigurationComplete } = useBoundStore(
    (state: StoreState) => ({
      isConfigurationComplete: state.isConfigurationComplete
    }),
    shallow
  )

  useEffect(() => {
    if (isConfigurationComplete) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigurationComplete])

  return (
    <DialogBox>
      <div className="mb-6 flex items-end">
        <h2 className="text-xl">{t('Laddar konfiguration')}</h2>
      </div>
      <LoadingCart />
    </DialogBox>
  )
}

export default LoadingConfigurationDialog
