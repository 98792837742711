import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/sharp-regular-svg-icons'
import { adjustToClosestMultipleOf25 } from '~/lib/utils'

type Props = {
  scale: number
  setScale: (scale: number) => void
  stageSize: Size
  mainPosition: Position
  setMainPosition: (position: Position) => void
}

const CanvasZoom = ({
  scale,
  setScale,
  stageSize,
  mainPosition,
  setMainPosition
}: Props) => {
  const zoom = (zoomIn: boolean) => {
    const integerScale = scale * 100
    const zoomLevel = adjustToClosestMultipleOf25(integerScale, zoomIn)
    let newScale = Math.min(Math.max(zoomLevel / 100, 0.25), 10)
    if (scale !== newScale || zoomLevel === 0) {
      newScale = zoomLevel === 0 ? 0.01 : newScale
      setScale(newScale)
      setMainPosition({
        x:
          stageSize.width / 2 -
          ((stageSize.width / 2 - mainPosition.x) / scale) * newScale,
        y:
          stageSize.height / 2 -
          ((stageSize.height / 2 - mainPosition.y) / scale) * newScale
      })
    }
  }

  return (
    <div className="absolute bottom-[70px] right-[calc(50%-60px)] flex translate-x-1/2 gap-[11px]">
      <button className="h-6 w-6 rounded-full bg-silver-75 hover:bg-grayscale-25 active:bg-grayscale-50 active:text-white">
        <FontAwesomeIcon
          onClick={() => zoom(false)}
          icon={faMinus}
        />
      </button>
      <div>{`${Math.round(100 * scale)}%`}</div>
      <button className="h-6 w-6 rounded-full bg-silver-75 hover:bg-grayscale-25 active:bg-grayscale-50 active:text-white">
        <FontAwesomeIcon
          onClick={() => zoom(true)}
          icon={faPlus}
        />
      </button>
    </div>
  )
}

export default CanvasZoom
