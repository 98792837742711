import { createRef, useEffect, useState } from 'react'
import { Layer, Stage } from 'react-konva'
import Konva from 'konva'
import PointerGuides from './PointerGuides'
import Roof from './Roof'
import { StoreState, useBoundStore } from '~/store'
import { KonvaEventObject } from 'konva/lib/Node'
import { scaleMmToPixels, scalePixelsToMm } from '~/utils/configurator'
import { shallow } from 'zustand/shallow'
import { useNavigate, useParams } from 'react-router-dom'
import CanvasZoom from '../CanvasZoom'
import CanvasToolbar from '../CanvasToolbar'
import { solarPanelClient } from '~/http/api'
import { useTranslation } from 'react-i18next'
import BackButton from '~/components/buttons/BackButton'
import { createPanelArea } from '~/lib/panelAreaUtils'
import {
  getScrollValue,
  handleLoadConfiguration,
  handleOnClick,
  handleOnDragEnd,
  handleOnDragStart,
  resetAllConfiguratorStates
} from '~/lib/canvasUtils'
import { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'

const Canvas = () => {
  const { t } = useTranslation()
  const { configurationId } = useParams()
  const navigateTo = useNavigate()

  const stageRef = createRef<Konva.Stage>()
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 })
  const [roofPointerPosition, setRoofPointerPosition] = useState({ x: 0, y: 0 })
  const [mainPosition, setMainPosition] = useState({ x: 0, y: 0 })
  const [roofPosition, setRoofPosition] = useState({ x: 0, y: 0 })
  const [scale, setScale] = useState(1)
  const [currentStage, setCurrentStage] = useState<Konva.Stage>()

  useEffect(() => {
    if (stageRef.current !== null && currentStage === undefined) {
      setCurrentStage(stageRef.current)
    }
  }, [stageRef])

  useEffect(() => {
    if (!isPositionDataValid && configurationId === undefined) {
      navigateTo('/')
    }
  }, [])

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsDrawing(false)
        setActiveArea(null)
      }
    }
    window.addEventListener('keydown', onKeydown)
    return () => {
      window.removeEventListener('keydown', onKeydown)
    }
  }, [])

  const handleLoadConfigurationError = (error: AxiosError) => {
    if (error.response?.status === 401 && user !== null) {
      setShowDialog('UnauthorizedConfigurationDialog')
    } else if (error.response?.status === 401 && user === null) {
      setShowDialog('LoginDialog')
    } else if (error.response?.status === 404) {
      setShowDialog('NotFoundConfigurationDialog')
    } else {
      Sentry.captureException(error)
      setShowDialog('ErrorLoadingConfigurationDialog')
    }
  }

  const loadConfiguration = () => {
    if (configurationId !== undefined) {
      resetAllConfiguratorStates()
      setShowConditions(false)
      setShowDialog('LoadingConfigurationDialog')
      solarPanelClient
        .get(`/load/${configurationId}`)
        .then(handleLoadConfiguration)
        .catch(handleLoadConfigurationError)
    }
  }

  useEffect(() => {
    loadConfiguration()
  }, [configurationId])

  const {
    roof,
    user,
    isDrawing,
    isRedrawing,
    activeArea,
    panelInfo,
    panelInfoLow,
    panelAreas,
    isConfigurationComplete,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    showResults,
    configurationSystem,
    isLoaded,
    setIsDrawing,
    setIsRedrawing,
    addPanelArea,
    setActiveArea,
    updatePanelArea,
    setPanelAreaSections,
    setIsConfigurationComplete,
    setShowConditions,
    setShowResults,
    setIsComplete,
    setShowDialog,
    setIsEdited
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      user: state.user,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      activeArea: state.activeArea,
      panelInfo: state.panelInfo,
      panelInfoLow: state.panelInfoLow,
      panelAreas: state.panelAreas,
      isConfigurationComplete: state.isConfigurationComplete,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.isRoofPropertiesDataValid,
      showResults: state.showResults,
      configurationSystem: state.conditions.configurationSystem,
      isLoaded: state.isLoaded,
      setIsDrawing: state.setIsDrawing,
      setIsRedrawing: state.setIsRedrawing,
      addPanelArea: state.addPanelArea,
      setActiveArea: state.setActiveArea,
      updatePanelArea: state.updatePanelArea,
      setPanelAreaSections: state.setPanelAreaSections,
      setIsConfigurationComplete: state.setIsConfigurationComplete,
      setShowConditions: state.setShowConditions,
      setShowResults: state.setShowResults,
      setIsApproved: state.setIsApproved,
      setIsComplete: state.setIsComplete,
      setShowDialog: state.setShowDialog,
      setIsEdited: state.setIsEdited
    }),
    shallow
  )

  useEffect(() => {
    if (user !== null && configurationId !== undefined && isLoaded === false) {
      loadConfiguration()
    }
  }, [user])

  const [sidebarWidth, setSidebarWidth] = useState(120)
  const [stageSize, setStageSize] = useState({
    width: window.innerWidth - sidebarWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    setSidebarWidth(isConfigurationComplete ? 0 : 120)
  }, [isConfigurationComplete])

  useEffect(() => {
    setStageSize({
      width: window.innerWidth - sidebarWidth,
      height: window.innerHeight
    })
  }, [sidebarWidth])

  /**
   * Handles stage size and roof position when conditions
   * or window is resized.
   */
  useEffect(() => {
    // Set roof position in the middle of window
    setRoofPosition({
      x: window.innerWidth / 2 - roof.measurementA / 10 / 2,
      y: window.innerHeight / 2 - roof.measurementB / 10 / 2
    })
    // Update stage size
    const handleWindowResize = () => {
      setStageSize({
        width: window.innerWidth - sidebarWidth,
        height: window.innerHeight
      })
    }
    // Resize event listener
    window.addEventListener('resize', handleWindowResize)
    return () => {
      document.removeEventListener('resize', handleWindowResize)
    }
  }, [roof])

  /**
   * Sets the roof position by updating the main layer position
   *
   * @param event
   */
  const handleMainDragMove = (event: KonvaEventObject<DragEvent>) => {
    if (activeArea !== null && event.target.attrs.name !== 'panel-area-group') {
      setActiveArea(null)
    }
    if (event.target.attrs.name !== 'Main') return
    setMainPosition(event.target.position())
  }

  /**
   * Used for creating panel area and handling isDrawing state.
   *
   * @param event KonvaEventObject<MouseEvent>
   */
  const handleStageMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    // Check if panel area should be created
    if (
      isDrawing &&
      !event.target.attrs.isPanelArea &&
      event.evt.button === 0 &&
      (activeArea === undefined || activeArea === null)
    ) {
      const panelAreaData = createPanelArea(
        roofPointerPosition,
        configurationSystem === 'low' ? panelInfoLow : panelInfo,
        roof.uid,
        configurationSystem === 'low' ? panelInfoLow.system : 'parallel'
      )
      setIsDrawing(true)
      setActiveArea(panelAreaData.uid)
      addPanelArea(panelAreaData)
      // Abort panel area drawing if other than left mouse button is clicked.
    } else if (event.evt.button !== 0 && isDrawing) {
      setIsDrawing(false)
    }
  }

  /**
   * Sets pointer, roof pointer position, while creating panel area
   * determines and updates the initial size of the panel area.
   *
   * @param event KonvaEventObject<MouseEvent>
   * @returns
   */
  const handleStageMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    // Position of the entire stage origin top left
    const position = event.target.getStage()?.getPointerPosition()
    const roofPointerPosition = event.target
      .getStage()
      ?.findOne('.roof')
      ?.getRelativePointerPosition()

    if (
      position === null ||
      position === undefined ||
      roofPointerPosition === null ||
      roofPointerPosition === undefined
    )
      return

    setPointerPosition(position)
    setRoofPointerPosition(roofPointerPosition)

    const panelArea = panelAreas.find(
      (panelArea) => panelArea.uid === activeArea
    )

    if (
      panelArea === undefined ||
      activeArea === null ||
      !isDrawing ||
      isRedrawing
    )
      return

    const rescaledPosition = scaleMmToPixels<Position>(panelArea.position)
    const width = roofPointerPosition.x - rescaledPosition.x
    const height = roofPointerPosition.y - rescaledPosition.y

    event.target.getStage()?.findOne(`#${activeArea}`)?.width(width)
    event.target.getStage()?.findOne(`#${activeArea}`)?.height(height)

    updatePanelArea({
      ...panelArea,
      size: scalePixelsToMm({ width, height })
    })
  }

  /**
   * Handles end of drawing panel area
   *
   * @param event
   */
  const handleStageMouseUp = (event: KonvaEventObject<MouseEvent>) => {
    if (isDrawing || isRedrawing) {
      finishDrawingPanelArea(event)
    }
  }

  /**
   * Handles states related to drawing panel area
   *
   * @param event
   */
  const finishDrawingPanelArea = (event: KonvaEventObject<MouseEvent>) => {
    if (
      !event.target.attrs.isPanelArea &&
      isDrawing &&
      activeArea !== undefined &&
      activeArea !== null
    ) {
      setIsDrawing(false)
    }
    setIsDrawing(false)
    setIsRedrawing(false)
  }

  /**
   * Zoom the entire main layer by scrolling relative to the pointer position
   *
   * @param event KonvaEventObject<WheelEvent>
   */
  const handleScrollZoom = (event: KonvaEventObject<WheelEvent>) => {
    if (isDrawing || isRedrawing) {
      return
    }
    const newScale = getScrollValue(scale, event)

    if (scale !== newScale) {
      setScale(newScale)
      setIsScrolling(true)
    } else if (isScrolling) {
      setIsScrolling(false)
    }
    // Reposition the main layer so that the zooming
    // is relative to the pointer position
    setMainPosition({
      x:
        pointerPosition.x -
        ((pointerPosition.x -
          (event.target.getStage()?.findOne('.Main')?.x() || 0)) /
          scale) *
          newScale,
      y:
        pointerPosition.y -
        ((pointerPosition.y -
          (event.target.getStage()?.findOne('.Main')?.y() || 0)) /
          scale) *
          newScale
    })
  }

  const handleAdjustConfiguration = () => {
    setIsComplete(false)
    setIsConfigurationComplete(false)
    setIsEdited(true)
    setPanelAreaSections([])
    if (showResults) {
      setShowResults(false)
    }
  }

  const [isScrolling, setIsScrolling] = useState(false)

  let timer: any = null

  useEffect(() => {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => setIsScrolling(false), 150)
    return () => {
      clearTimeout(timer)
    }
  }, [scale])

  const [isDragging, setIsDragging] = useState(false)
  const [isDraggingPanelArea, setIsDraggingPanelArea] = useState(false)

  //TODO: Test how moving this into roof is affecting the performance.

  // const [currentRoof, setCurrentRoof] = useState<Konva.Group | null>(null)
  // const roofRef = createRef<Konva.Group>()

  // useEffect(() => {
  //   if (currentRoof && (isScrolling || isDragging)) {
  //     cacheNode(currentRoof, 1)
  //   } else if (currentRoof && !isScrolling && !isDragging) {
  //     clearNodeCache(currentRoof)
  //   }
  // }, [isScrolling, isDragging])

  // useEffect(() => {
  //   if (roofRef.current !== null && currentRoof === null) {
  //     setCurrentRoof(roofRef.current)
  //   }
  // }, [roofRef])

  return (
    <div
      id="canvas-wrapper"
      className="flex"
    >
      {!isConfigurationComplete ? (
        <CanvasToolbar />
      ) : (
        <BackButton
          className="absolute left-20 top-[84px] z-10"
          onClick={handleAdjustConfiguration}
        >
          {t('Justera konfiguration')}
        </BackButton>
      )}
      {isDrawing ? (
        <div className="pointer-events-none absolute right-1/2 top-[70px] z-30 translate-x-1/2 text-base font-light">
          {t('Klicka på Esc för att avsluta ritfunktionen')}
        </div>
      ) : null}
      <Stage
        className="bg-canvas"
        id="stage"
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        draggable={false}
        onMouseMove={handleStageMouseMove}
        onMouseDown={handleStageMouseDown}
        onMouseUp={handleStageMouseUp}
        onClick={handleOnClick}
        onWheel={handleScrollZoom}
      >
        {isPositionDataValid &&
        isRoofMaterialDataValid &&
        isRoofPropertiesDataValid ? (
          <Layer
            name="Main"
            draggable={!isDrawing}
            onDragMove={handleMainDragMove}
            onDragStart={(event) =>
              handleOnDragStart(event, setIsDragging, setIsDraggingPanelArea)
            }
            onDragEnd={() => {
              handleOnDragEnd(setIsDragging, setIsDraggingPanelArea)
            }}
            scale={{ x: scale, y: scale }}
            x={mainPosition.x}
            y={mainPosition.y}
          >
            {isPositionDataValid &&
            isRoofMaterialDataValid &&
            isRoofPropertiesDataValid ? (
              <Roof
                position={roofPosition}
                scale={!isScrolling ? scale : 1}
                // roofRef={roofRef}
                isScrolling={isScrolling}
                isDragging={isDragging}
              />
            ) : null}
          </Layer>
        ) : null}
        {(isDrawing || activeArea !== null) && !isDraggingPanelArea ? (
          <Layer name="pointerGuides">
            <PointerGuides
              visible={true}
              pointerPosition={pointerPosition}
              mainPosition={mainPosition}
              roofPosition={roofPosition}
              scale={scale}
            />
          </Layer>
        ) : null}
      </Stage>
      <CanvasZoom
        scale={scale}
        setScale={setScale}
        stageSize={stageSize}
        mainPosition={mainPosition}
        setMainPosition={setMainPosition}
      />
    </div>
  )
}

export default Canvas
