import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '../store'
import { shallow } from 'zustand/shallow'
import { createPanelSectionImagesData, getImage } from '../utils/images'
import RoofImageStage from './canvas/konva/image/RoofImageStage'
import { scaleMmToPixels, scalePixelsToMm } from '../utils/configurator'

const ImageHandler = React.memo(() => {
  const [currentRoofImageStage, setCurrentRoofImageStage] =
    useState<Konva.Stage>()
  const [positionsOverviewImage, setPositionsOverviewImage] =
    useState<string>('')
  const [sectionsOverviewImage, setSectionsOverviewImage] = useState<string>('')
  const scale = 1

  const {
    roof,
    roofCoordinates,
    panelAreas,
    panelAreaSections,
    runImageCreation,
    panelSectionImagesData,
    panelSectionsImageView,
    positionsOverviewImageView,
    sectionsOverviewImageView,
    roofImageScale,
    isConfigurationComplete,
    isLoaded,
    setRunImageCreation,
    setPanelSectionImagesData,
    setPanelSectionsImageView,
    setPositionsOverviewImageView,
    setSectionsOverviewImageView,
    updateRoof,
    setIsComplete
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      roofCoordinates: state.roofCoordinates,
      panelAreas: state.panelAreas,
      panelAreaSections: state.panelAreaSections,
      runImageCreation: state.runImageCreation,
      panelSectionImagesData: state.panelSectionImagesData,
      panelSectionsImageView: state.panelSectionsImageView,
      positionsOverviewImageView: state.positionsOverviewImageView,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      roofImageScale: state.roofImageScale,
      isConfigurationComplete: state.isConfigurationComplete,
      isLoaded: state.isLoaded,
      setRunImageCreation: state.setRunImageCreation,
      setPanelSectionsImageView: state.setPanelSectionsImageView,
      setPositionsOverviewImageView: state.setPositionsOverviewImageView,
      setSectionsOverviewImageView: state.setSectionsOverviewImageView,
      setPanelSectionImagesData: state.setPanelSectionImagesData,
      updateRoof: state.updateRoof,
      setIsComplete: state.setIsComplete
    }),
    shallow
  )

  useEffect(() => {
    if (
      currentRoofImageStage !== undefined &&
      !isLoaded &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      setPanelSectionsImageView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoofImageStage, isConfigurationComplete])

  useEffect(() => {
    if (
      panelSectionsImageView &&
      currentRoofImageStage !== undefined &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      const minRoofX = scaleMmToPixels<number>(
        Math.abs(
          roofCoordinates !== undefined
            ? Math.min(...roofCoordinates.map((coordinate) => coordinate[0]))
            : 0
        )
      )

      setPanelSectionImagesData(
        createPanelSectionImagesData(
          currentRoofImageStage,
          panelAreaSections,
          { x: minRoofX, y: 0 },
          roof.attachment,
          panelAreas,
          scale
        )
      )
      setPanelSectionsImageView(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelSectionsImageView])

  useEffect(() => {
    if (
      runImageCreation &&
      currentRoofImageStage !== undefined &&
      isConfigurationComplete &&
      !isLoaded &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      setPositionsOverviewImageView(true)
    }
  }, [runImageCreation, currentRoofImageStage, isConfigurationComplete])

  useEffect(() => {
    if (
      positionsOverviewImageView &&
      currentRoofImageStage !== undefined &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      const roofSize = scalePixelsToMm<Size>({
        width: currentRoofImageStage.getAttr('width'),
        height: currentRoofImageStage.getAttr('height')
      })
      const image = getImage(currentRoofImageStage, { x: 0, y: 0 }, roofSize, 1)
      setPositionsOverviewImage(image)
      setPositionsOverviewImageView(false)
      setSectionsOverviewImageView(true)
    }
  }, [positionsOverviewImageView])

  useEffect(() => {
    if (
      sectionsOverviewImageView &&
      currentRoofImageStage !== undefined &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      const coordinates: Position[] = panelAreaSections.flatMap(
        (panelAreaSection) => [
          panelAreaSection.position,
          {
            x: panelAreaSection.position.x + panelAreaSection.size.width,
            y: panelAreaSection.position.y
          },
          {
            x: panelAreaSection.position.x + panelAreaSection.size.width,
            y: panelAreaSection.position.y + panelAreaSection.size.height
          },
          {
            x: panelAreaSection.position.x,
            y: panelAreaSection.position.y + panelAreaSection.size.height
          }
        ]
      )
      const minX = Math.min(...coordinates.map((coordinate) => coordinate.x))
      const maxX = Math.max(...coordinates.map((coordinate) => coordinate.x))
      const minY = Math.min(...coordinates.map((coordinate) => coordinate.y))
      const maxY = Math.max(...coordinates.map((coordinate) => coordinate.y))
      const minRoofX =
        roofCoordinates !== undefined
          ? Math.min(...roofCoordinates.map((coordinate) => coordinate[0]))
          : 0
      const image = getImage(
        currentRoofImageStage,
        { x: minX - minRoofX, y: minY },
        {
          width: maxX - minX + scalePixelsToMm<number>(2 / roofImageScale),
          height: maxY - minY + scalePixelsToMm<number>(2 / roofImageScale)
        },
        1
      )
      setSectionsOverviewImage(image)
      setSectionsOverviewImageView(false)
    }
  }, [sectionsOverviewImageView])

  useEffect(() => {
    if (
      positionsOverviewImage !== '' &&
      sectionsOverviewImage !== '' &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      updateRoof({
        ...roof,
        positionsOverviewImage,
        sectionsOverviewImage
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsOverviewImage, sectionsOverviewImage])

  useEffect(() => {
    if (
      panelSectionImagesData.length === panelAreaSections.length &&
      panelAreaSections.length > 0
    ) {
      setTimeout(() => {
        setRunImageCreation(true)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelSectionImagesData, isConfigurationComplete])

  useEffect(() => {
    const isPanelAreaSectionsImagesGenerated = () =>
      panelAreaSections.some(
        (panelAreaSection) => panelAreaSection.image !== undefined
      )

    if (isPanelAreaSectionsImagesGenerated()) {
      setIsComplete(true)
      setRunImageCreation(false)
      setPanelSectionImagesData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAreaSections])

  const handleChildRefSet = (ref: Konva.Stage) => {
    if (ref) {
      setCurrentRoofImageStage(ref)
    }
  }

  return <RoofImageStage onRefSet={handleChildRefSet} />
})

export default ImageHandler
