import { StoreState, useBoundStore } from '~/store'
import Drawer from './Drawer'
import FormPanelSettings from './form/FormPanelSettings'
import LowSlopePanelSettings from './form/lowSlope/LowSlopePanelSettings'

const DrawerPanelSettings = () => {
  const {
    configurationSystem,
    showPanelSettings,
    isDrawing,
    isRedrawing,
    setShowPanelSettings
  } = useBoundStore((state: StoreState) => ({
    configurationSystem: state.conditions.configurationSystem,
    showPanelSettings: state.showPanelSettings,
    isDrawing: state.isDrawing,
    isRedrawing: state.isRedrawing,
    setShowPanelSettings: state.setShowPanelSettings
  }))
  if (isDrawing || isRedrawing) {
    return
  }
  return (
    <Drawer
      isOpen={showPanelSettings}
      handleClose={() => setShowPanelSettings(false)}
    >
      {configurationSystem === 'parallel' ? (
        <FormPanelSettings />
      ) : (
        <LowSlopePanelSettings />
      )}
    </Drawer>
  )
}

export default DrawerPanelSettings
