import CloseConfigurator from '../CloseConfigurator'
import PositionDrawer from '../PositionDrawer'

const PositionPage = () => {
  return (
    <>
      <PositionDrawer />
      <CloseConfigurator />
      <div id="weland-google-maps-container"></div>
    </>
  )
}

export default PositionPage
