import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import SystemRadio from './fields/SystemRadio'

import i18n from '../../lib/i18n'
const { t } = i18n

import parallel from './../../assets/parallel.png'
import lowSlope from './../../assets/low-slope.png'
import { Trans } from 'react-i18next'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import { useNavigate } from 'react-router-dom'

const formOptionsSystem = [
  {
    value: 'parallel',
    label: t('Parallella system'),
    image: parallel
  },
  {
    value: 'low',
    label: t('Låglutande system'),
    image: lowSlope
  }
]

const validationSchema = z.object({
  system: z.string({ invalid_type_error: t('System är obligatorisk') })
})

type ValidationSchema = z.infer<typeof validationSchema>

const FormSystemPage = React.memo(() => {
  const navigateTo = useNavigate()
  const {
    conditions,
    roof,
    panelInfo,
    panelInfoLow,
    updateRoof,
    updateConditions,
    setPanelInfo,
    setPanelInfoLow
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      conditions: state.conditions,
      panelInfo: state.panelInfo,
      panelInfoLow: state.panelInfoLow,
      updateRoof: state.updateRoof,
      updateConditions: state.updateConditions,
      setPanelInfo: state.setPanelInfo,
      setPanelInfoLow: state.setPanelInfoLow
    }),
    shallow
  )

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      system: conditions.configurationSystem
    }
  })

  const { handleSubmit, watch } = form

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    handleOnSubmit(data)
  }

  const handleSystemState = (system: string) => {
    if (system === 'low') {
      updateConditions({
        ...conditions,
        configurationSystem: system as typeof conditions.configurationSystem,
        surfaceTreatment: system === 'low' ? '' : 'S'
      })

      const testDefaultValues = import.meta.env.DEV
        ? {
            ridgeHeight: 5000,
            slope: 2,
            measurementA: 16000,
            measurementB: 10000,
            orientationFromSouth: 0
          }
        : {}

      updateRoof({
        ...roof,
        covering: 'flat',
        attachment: 'sealing_plate_flat',
        type: 'pent',
        shape: 'rectangle',
        slope: 0,
        orientationFromSouth: 0,
        ...testDefaultValues
      })

      const lowSlopePanelMounting = localStorage.getItem(
        'lowSlopePanelMounting'
      )

      setPanelInfoLow({
        ...panelInfoLow,
        mounting: lowSlopePanelMounting ? lowSlopePanelMounting : '2'
      })
    } else {
      if (import.meta.env.DEV) {
        const testDefaultValues = {
          ridgeHeight: 5000,
          slope: 2,
          measurementA: 15000,
          measurementB: 10000
        }

        updateRoof({
          ...roof,
          ...testDefaultValues
        })
      }

      updateConditions({
        ...conditions,
        configurationSystem: system as typeof conditions.configurationSystem
      })

      const PanelMounting = localStorage.getItem('panelMounting')

      // Reset panel info to remove default values from low slope system
      setPanelInfo({
        width: panelInfo.width,
        height: panelInfo.height,
        heightMounted: panelInfo.heightMounted,
        widthMounted: panelInfo.widthMounted,
        weight: panelInfo.weight,
        gap: panelInfo.gap,
        mounting: PanelMounting !== null ? PanelMounting : '90-portrait',
        useSupportPlates: panelInfo.useSupportPlates,
        useThreeRails: panelInfo.useThreeRails
      })
    }
  }

  const handleOnSubmit = (data: ValidationSchema) => {
    handleSystemState(data.system)
    navigateTo('/position')
  }

  const system = watch('system')

  return (
    <FormProvider {...form}>
      <SystemRadio
        name="system"
        options={formOptionsSystem}
        className="mb-4"
      />
      {system === 'low' ? (
        <Trans i18nKey="system_page_info">
          <p className="mb-8 max-w-[328px]">
            <span className="font-bold">Information:</span> Låglutande system
            kan endast beräknas på tak med taklutning mellan 0-10 grader.
          </p>
        </Trans>
      ) : null}
      {system !== undefined ? (
        <div className="col-span-full flex justify-center pt-2">
          <Button onClick={handleSubmit(onSubmit)}>{t('Fortsätt')}</Button>
        </div>
      ) : null}
    </FormProvider>
  )
})

export default FormSystemPage
