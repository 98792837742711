import { StateCreator } from 'zustand'

const initialState: User | null = null
// {
//   firstName: '',
//   lastName: '',
//   email: '',
//   phone: '',
//   address: '',
//   zip: '',
//   city: '',
//   companyName: '',
//   companyRole: '',
//   marketing: '',
//   canSeePrices: false
// }

export interface UserSlice {
  user: User | null
  setUser: (user: User | null) => void
}

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
  user: initialState,
  setUser: (userData: User | null) => set(() => ({ user: userData }))
})
