import { useCallback, useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import DistanceLabel from './DistanceLabel'
import {
  getDistanceLabelsBetweenPanels,
  getMountDistanceLabels,
  getPanelAreaByUid,
  getPanelAreaRails,
  getRailDistanceLabels
} from '~/lib/distanceLabelsUtils'

const DistanceLabels = () => {
  const {
    roof,
    panelAreas,
    panelAreaSections,
    configurationSystem,
    positionsOverviewImageView,
    sectionsOverviewImageView,
    panelSectionsImageView
  } = useBoundStore(
    (state: StoreState) => ({
      configurationSystem: state.conditions.configurationSystem,
      roof: state.roof,
      panelAreaSections: state.panelAreaSections,
      panelAreas: state.panelAreas,
      activeArea: state.activeArea,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      positionsOverviewImageView: state.positionsOverviewImageView,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      panelSectionsImageView: state.panelSectionsImageView,
      roofImageScale: state.roofImageScale
    }),
    shallow
  )

  const [distanceLabels, setDistanceLabels] = useState<DistanceLabel[]>([])

  const handleDistanceLabels = useCallback(() => {
    let mountDistanceLabels: DistanceLabel[] = []
    let railDistanceLabels: DistanceLabel[] = []
    let betweenPanelsDistanceLabels: DistanceLabel[] = []
    const panelAreaRailsData = getPanelAreaRails(panelAreaSections, panelAreas)
    Object.keys(panelAreaRailsData).forEach((panelAreaUid) => {
      const { rails, panelInfo, edgeDistance, protrudingRail } =
        panelAreaRailsData[panelAreaUid]
      if (panelInfo === null || edgeDistance === null) return
      betweenPanelsDistanceLabels.push(
        ...getDistanceLabelsBetweenPanels(
          rails,
          panelInfo,
          edgeDistance,
          protrudingRail,
          panelAreaSections
        )
      )
    })

    panelAreaSections.forEach((panelAreaSection) => {
      const panelArea = getPanelAreaByUid(
        panelAreas,
        panelAreaSection.panelAreaUid
      )

      if (panelArea === undefined) {
        return
      }
      if (roof.attachment !== 'long_rail' && roof.attachment !== 'short_rail') {
        mountDistanceLabels.push(
          ...getMountDistanceLabels(
            panelAreaSection.result.mounts,
            panelArea.railAngle,
            panelAreaSection.result.isApproved
          )
        )
      }

      // const railLabels = []

      const railLabels = getRailDistanceLabels(
        panelAreaSection.result.mounts,
        panelArea.railAngle,
        panelAreaSection.result.isApproved
      )
      if (roof.attachment === 'long_rail' || roof.attachment === 'short_rail') {
        railDistanceLabels.push(railLabels[0])
      } else {
        railDistanceLabels.push(...railLabels)
      }
    })

    const labels =
      configurationSystem === 'low'
        ? [
            ...mountDistanceLabels,
            ...betweenPanelsDistanceLabels,
            ...railDistanceLabels
          ]
        : [...mountDistanceLabels, ...railDistanceLabels]
    setDistanceLabels(labels)
  }, [panelAreaSections])

  useEffect(() => {
    handleDistanceLabels()
  }, [panelAreaSections])

  return (
    <>
      {sectionsOverviewImageView === false &&
      positionsOverviewImageView === false
        ? distanceLabels.map((distanceLabel, index) => {
            if (
              panelSectionsImageView &&
              distanceLabel.betweenSections === true
            ) {
              return
            }
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
                railAngle={distanceLabel.railAngle}
                isApproved={distanceLabel.isApproved}
              />
            )
          })
        : null}
    </>
  )
}

export default DistanceLabels
