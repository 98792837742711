import PageWrapper from './PageWrapper'
import { useTranslation } from 'react-i18next'
import CloseConfigurator from '../CloseConfigurator'
import FormSystemPage from '../form/FormSystemPage'
import { useEffect } from 'react'
import { StoreState, useBoundStore } from '../../store'
import { shallow } from 'zustand/shallow'

const SystemPage = () => {
  const { t } = useTranslation()
  const {
    resetConfigurator,
    resetConditions,
    resetRoof,
    resetPanelArea,
    resetPanelAreaSection,
    resetImage,
    setIsLoaded
  } = useBoundStore(
    (state: StoreState) => ({
      resetConfigurator: state.resetConfigurator,
      resetConditions: state.resetConditions,
      resetRoof: state.resetRoof,
      resetPanelArea: state.resetPanelArea,
      resetPanelAreaSection: state.resetPanelAreaSection,
      resetImage: state.resetImage,
      setIsLoaded: state.setIsLoaded
    }),
    shallow
  )

  const resetAllConfiguratorStates = () => {
    resetConfigurator()
    resetConditions()
    resetRoof()
    resetPanelArea()
    resetPanelAreaSection()
    resetImage()
    setIsLoaded(true)
  }

  useEffect(() => {
    resetAllConfiguratorStates()
  }, [])
  return (
    <PageWrapper className=" justify-center">
      <CloseConfigurator />
      <div className="max-w-lg text-center">
        <h1 className="heading-l mb-14">
          {t('Vilket system vill du konfigurera?')}
        </h1>
      </div>
      <div>
        <FormSystemPage />
      </div>
    </PageWrapper>
  )
}

export default SystemPage
