import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

const sentryInit = () => {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        enabled: !import.meta.env.DEV
    });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export {
    sentryInit,
    SentryRoutes
};