import {
  Controller,
  useFormContext,
  RegisterOptions,
  FieldValues
} from 'react-hook-form'
import { SelectHTMLAttributes } from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import { cn } from '~/utils/tailwind'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/sharp-solid-svg-icons'

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: keyof FieldValues
  label?: string
  options: formOptionsItemType[]
  rules?: RegisterOptions<FieldValues>
  placeholder?: string
  className?: string
}

export const ReactSelect: React.FC<ISelectProps> = ({
  name,
  label,
  options,
  placeholder,
  rules,
  disabled,
  className
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={cn('relative col-span-2', className)}>
      <label
        htmlFor={name}
        className="mb-2 flex w-full text-sm font-bold uppercase"
      >
        {label}
      </label>
      <Controller
        name={name}
        rules={rules}
        control={control}
        disabled={disabled}
        render={({ field }) => (
          <Select
            components={{
              DropdownIndicator: () => <FontAwesomeIcon icon={faChevronDown} />,
              IndicatorSeparator: () => null
            }}
            className="weland-react-select"
            classNamePrefix="weland-react-select"
            {...field}
            placeholder={placeholder}
            options={options}
          />
        )}
      />
      <FieldErrorMessage
        name={name}
        errors={errors}
      />
    </div>
  )
}
