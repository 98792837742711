import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from '~/components/form/fields/Input'
import Button from '~/components/buttons/Button'
import Radio from '~/components/form/fields/Radio'
import Checkbox from '~/components/form/fields/Checkbox'
import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import eastWestSystemImage from '~/assets/east_west_system.png'
import SouthSystemImage from '~/assets/south_system.png'
import panelMounting_2_2 from '~/assets/PANELMONTERING_2_2.svg'
import Info from '~/components/Info'
import Slider from '../fields/Slider'
import { getPanelHeightMounted, getPanelWidthMounted } from '~/lib/panel'
import { t } from 'i18next'
const validationSchema = z
  .object({
    system: z.string(),
    width: z.number(),
    height: z.number(),
    weight: z.number(),
    mounting: z.string(),
    useSupportPlates: z.boolean(),
    gapRow: z.number()
  })
  .refine(
    (schema) =>
      (schema.system === 'south' && schema.height >= 1038) ||
      schema.system !== 'south',
    {
      message: t('Höjd måste vara större eller lika med 1038'),
      path: ['height']
    }
  )
  // .refine(
  //   (schema) =>
  //     (schema.system === 'south' && schema.height <= 1134) ||
  //     schema.system !== 'south',
  //   {
  //     message: t('Höjd måste vara mindre eller lika med 1134'),
  //     path: ['height']
  //   }
  // )
  .refine(
    (schema) =>
      (schema.system === 'east/west' && schema.width >= 1038) ||
      schema.system !== 'east/west',
    {
      message: t('Bredd måste vara större eller lika med 1038'),
      path: ['width']
    }
  )
  // .refine(
  //   (schema) =>
  //     (schema.system === 'east/west' && schema.width <= 1134) ||
  //     schema.system !== 'east/west',
  //   {
  //     message: t('Bredd måste vara mindre eller lika med 1134'),
  //     path: ['width']
  //   }
  // )
  .refine(
    (schema) =>
      (schema.system === 'south' && schema.gapRow >= 105) ||
      schema.system !== 'south',
    {
      message: t(
        'Avstånd mellan panelrader måste vara större eller lika med 105'
      ),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'south' && schema.gapRow <= 720) ||
      schema.system !== 'south',
    {
      message: t('Avstånd mellan panelrader måste mindre eller lika med 720'),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'east/west' && schema.gapRow >= 160) ||
      schema.system !== 'east/west',
    {
      message: t(
        'Avstånd mellan panelrader måste vara större eller lika med 160'
      ),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'east/west' && schema.gapRow <= 720) ||
      schema.system !== 'east/west',
    {
      message: t('Avstånd mellan panelrader måste mindre eller lika med 720'),
      path: ['gapRow']
    }
  )

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const LowSlopePanelSettings = React.memo(() => {
  const { t } = useTranslation()

  const {
    activeArea,
    panelAreas,
    panelInfoLow,
    setShowPanelSettings,
    updatePanelArea,
    setPanelInfoLow
  } = useBoundStore(
    (state: StoreState) => ({
      activeArea: state.activeArea,
      panelAreas: state.panelAreas,
      panelInfoLow: state.panelInfoLow,
      setShowPanelSettings: state.setShowPanelSettings,
      updatePanelArea: state.updatePanelArea,
      setPanelInfoLow: state.setPanelInfoLow
    }),
    shallow
  )

  const panelAreaIndex = panelAreas.findIndex(
    (panelArea) => panelArea.uid === activeArea
  )

  useEffect(() => {
    if (activeArea !== null && panelAreaIndex !== -1) {
      const panelInfoLow = panelAreas[panelAreaIndex].panelInfo as PanelInfoLow
      reset(panelInfoLow)
    }
  }, [activeArea])

  const selectedArea = activeArea !== null

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      system: panelInfoLow.system,
      width: panelInfoLow.width,
      height: panelInfoLow.height,
      weight: panelInfoLow.weight,
      mounting: '2',
      useSupportPlates: true,
      gapRow: panelInfoLow.gapRow
    }
  })

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty }
  } = form

  const width = watch('width')
  const height = watch('height')
  const system = watch('system')
  const gapRow = watch('gapRow')

  const [gapRowMin, setGapRowMin] = useState(system === 'south' ? 105 : 160)

  useEffect(() => {
    if (system === 'south') {
      setGapRowMin(105)
      setValue('gapRow', 400)
    } else {
      setGapRowMin(160)
      setValue('gapRow', 350)
    }
  }, [system])

  useEffect(() => {
    if (system === 'south') {
      setValue('gapRow', 400)
    } else {
      setValue('gapRow', 350)
    }
  }, [])

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    let validData = null
    try {
      validData = validationSchema.parse(data)
      const panelInfoData = {
        ...panelInfoLow,
        ...validData,
        heightMounted: getPanelHeightMounted(
          validData.system,
          validData.height
        ),
        widthMounted: getPanelWidthMounted(validData.system, validData.width)
      }
      if (activeArea !== null) {
        updatePanelArea({
          ...panelAreas[panelAreaIndex],
          panelInfo: panelInfoData,
          useSupportPlates: validData.useSupportPlates,
          railsPerRow: parseInt(validData.mounting)
        })
      } else {
        setPanelInfoLow(panelInfoData)
        localStorage.setItem('panelWidth', validData.width.toString())
        localStorage.setItem('panelHeight', validData.height.toString())
        localStorage.setItem('panelWeight', validData.weight.toString())
        localStorage.setItem('LowSlopePanelMounting', validData.mounting)
        localStorage.setItem(
          'useSupportPlates',
          validData.useSupportPlates.toString()
        )
        localStorage.setItem('panelSystem', validData.system)
        localStorage.setItem('gapRow', validData.gapRow.toString())
      }
      setShowPanelSettings(false)
      reset({
        system: validData.system,
        height: validData.height,
        width: validData.width,
        weight: validData.weight,
        mounting: validData.mounting,
        useSupportPlates: validData.useSupportPlates,
        gapRow: validData.gapRow
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  useEffect(() => {
    if (
      (system === 'south' && width < height) ||
      (system === 'east/west' && width > height)
    ) {
      setValue('width', height)
      setValue('height', width)
    }
  }, [system])

  return (
    <FormProvider {...form}>
      <form
        className="mb-6 grid w-full grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="heading-m col-span-full mb-6">
          {panelAreaIndex > -1
            ? `${t('Redigera inställningar - Panelyta')} ${panelAreaIndex + 1}`
            : t('Panelinställningar')}
        </h2>
        <h3 className="col-span-full text-lg font-bold">
          {t('Typ av system')}
        </h3>
        <Radio
          name="system"
          options={[
            {
              value: 'east/west',
              label: t('Öst/väst-system'),
              image: eastWestSystemImage
            },
            {
              value: 'south',
              label: t('Sydsystem'),
              image: SouthSystemImage
              // rules: { disabled: true }
            }
          ]}
          rules={{ required: { value: true, message: t('Obligatorisk') } }}
        />
        <h3 className="col-span-full text-lg font-bold">
          {t('Dimensioner')}{' '}
          <Info
            id={'dimension-tooltip'}
            text={'Panelen ska ha en bredd på minst 1038mm och max 1134mm.'}
          />
        </h3>
        <Input
          name="width"
          type="number"
          label={t('Bredd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
          unit={t('mm')}
        />
        <Input
          name="height"
          type="number"
          label={t('Höjd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
          // rules={{ min: 1038, max: 1134 }}
          unit={t('mm')}
        />
        <Input
          name="weight"
          type="number"
          label={t('Vikt') || ''}
          className="col-span-1 mb-4"
          required
          disabled={selectedArea}
          unit={t('kg')}
        />
        <h3 className="col-span-full text-lg font-bold">
          {t('Panelmontering')}
        </h3>
        <Radio
          name="mounting"
          options={[
            {
              value: '2',
              image: panelMounting_2_2
            }
          ]}
          vertical
          disabled={selectedArea}
        />
        <Slider
          name="gapRow"
          label={
            t('Avstånd panelrader ({{gapRowMin}}-720mm)', {
              gapRowMin
            }) || ''
          }
          min={gapRowMin}
          max={720}
          unit={t('mm')}
          rules={{
            disabled: selectedArea
          }}
          wide
          disabled={selectedArea}
          infoMessage={
            gapRow < 350 && system === 'south'
              ? t('Ger dålig instrålningsvinkel')
              : undefined
          }
        />
        <h3 className="col-span-full text-lg font-bold">{t('Tillval')}</h3>
        <Checkbox
          name="useSupportPlates"
          label={t('Använd stödplattor')}
          disabled
        />
        <div className="col-start-2 flex justify-end">
          <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default LowSlopePanelSettings
