import { StateCreator } from 'zustand'
import { v4 as uuidv4 } from 'uuid'

const initialState: Roof = {
  uid: uuidv4(),
  snowGuard: false,
  covering: 'concrete_tile',
  tileWidth: 300,
  tileHeight: 330,
  topDistance: 100,
  attachment: 'base_plate',
  lathDimension: '45x70',
  sheetMetalMaterial: 'steel_0.5',
  type: 'gable',
  shape: 'rectangle',
  measurementA: 0,
  measurementB: 0,
  measurementC: 0,
  slope: 1,
  ridgeHeight: 0
}

export interface RoofSlice {
  roof: Roof
  updateRoof: (roof: Roof) => void
  resetRoof: () => void
}

export const createRoofSlice: StateCreator<RoofSlice> = (set) => ({
  roof: initialState,
  updateRoof: (roofData: Roof) => set(() => ({ roof: roofData })),
  resetRoof: () => {
    set({ roof: initialState })
  }
})
