import { Rect, Group, Text } from 'react-konva'
import KonvaButton from './KonvaButton'
import { useTranslation } from 'react-i18next'

type Props = {
  position: {
    x: number
    y: number
  }
  scale: number
  confirmClick: () => void
  denyClick: () => void
}

const RemovePanelAreaPopup = ({
  position,
  scale,
  confirmClick,
  denyClick
}: Props) => {
  const { t } = useTranslation()
  return (
    <Group
      name="remove-panel-popup"
      x={position.x - 102 / scale}
      y={position.y - 170 / scale}
    >
      <Rect
        width={10 / scale}
        height={10 / scale}
        fill="#404040"
        x={102 / scale}
        y={(148 - 8) / scale}
        rotation={45}
      />
      <Rect
        width={178 / scale}
        height={148 / scale}
        fill="#404040"
      />
      <Text
        width={(178 - 32) / scale}
        height={170 / scale}
        x={20 / scale}
        y={20 / scale}
        text={t('Är du säker på att du vill ta bort denna panelyta?')}
        fontSize={14 / scale}
        lineHeight={1.35}
        fontFamily="Roboto"
        fill="#ffffff"
      />
      <KonvaButton
        text={t('Avbryt')}
        position={{ x: 16 / scale, y: 97 / scale }}
        onClick={() => denyClick()}
        inverted={false}
        scale={scale}
      />
      <KonvaButton
        text={t('Ja')}
        position={{ x: (16 + 69 + 8) / scale, y: 97 / scale }}
        onClick={() => confirmClick()}
        inverted
        scale={scale}
      />
    </Group>
  )
}

export default RemovePanelAreaPopup

// import React from 'react'
// import PropTypes from 'prop-types'

// const RemovePanelAreaPopup = ({ position, confirmClick, denyClick }) => (
//   <Group name="remove-panel-popup" x={position.x - 102} y={position.y - 170}>
//     <Rect
//       width={10}
//       height={10}
//       fill="hsl(240, 2%, 16%)"
//       x={102}
//       y={148 - 8}
//       rotation={45}
//     />
//     <Rect width={204} height={148} fill="hsl(240, 2%, 16%)" cornerRadius={4} />
//     <Text
//       width={204 - 40}
//       height={170}
//       x={20}
//       y={20}
//       text="Vill du verkligen ta bort denna yta?"
//       fontSize={14}
//       fontFamily="Roboto"
//       fill="hsl(0, 0%, 100%)"
//     />
//     <CanvasButton
//       text="Nej"
//       position={{ x: 20, y: 85 }}
//       onClick={() => denyClick()}
//     />
//     <CanvasButton
//       text="Ja"
//       position={{ x: 20 + 77 + 10, y: 85 }}
//       onClick={() => confirmClick()}
//       inverted
//     />
//   </Group>
// )

// RemovePanelAreaPopup.propTypes = {
//   position: PropTypes.shape({
//     x: PropTypes.number.isRequired,
//     y: PropTypes.number.isRequired,
//   }).isRequired,
//   confirmClick: PropTypes.func.isRequired,
//   denyClick: PropTypes.func.isRequired,
// }

// export default RemovePanelAreaPopup
