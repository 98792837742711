import * as React from 'react'
import { useFormContext, RegisterOptions, FieldValues } from 'react-hook-form'
import { InputHTMLAttributes } from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import { v4 as uuidv4 } from 'uuid'
import { cn } from '~/utils/tailwind'
import { Tooltip } from 'react-tooltip'
import reverse from '~/assets/reverse.svg'
import { useTranslation } from 'react-i18next'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: keyof FieldValues
  options: formOptionsItemType[]
  rules?: RegisterOptions<FieldValues>
  label?: string
  hideRadio?: boolean
  imageSize?: {
    height: number
    width: number
  }
  columnPosition?: {
    start?: number
    span?: number
    end?: number
  }
  className?: string
}

const Input: React.FC<IInputProps> = React.memo(
  ({ name, options, rules, disabled, label, className }) => {
    const { t } = useTranslation()
    const {
      register,
      formState: { errors },
      getValues
    } = useFormContext()

    const uuid = uuidv4()
    const uuid2 = uuidv4()
    const values = getValues()

    const handleImage = (option: formOptionsItemType) => {
      if (option.image === undefined) return null
      const renderImage =
        typeof option.image === 'string' ? (
          <img
            className={cn(
              'h-full w-auto object-cover',
              name === 'roofShape' && 'mb-3 aspect-auto w-full p-2',
              (name === 'roofType' || name === 'mounting') && 'w-full'
            )}
            src={option.image}
          />
        ) : (
          <img
            className={cn('aspect-square h-full w-auto object-cover')}
            src={option.image(option.value, values)}
          />
        )
      return renderImage
    }

    return (
      <div className="relative col-span-full mb-4">
        {label && (
          <div className="mb-3 flex w-full text-sm font-bold uppercase">
            {label}
          </div>
        )}
        <div className="relative flex gap-6">
          {options.map((option, index) => {
            let value = option.value
            let value2: string | boolean = false

            if (typeof option.value === 'object' && option.value.length == 2) {
              value = option.value[0]
              value2 = option.value[1]
            }

            return (
              <div
                key={index}
                className={cn(
                  'relative ',
                  value2 !== false &&
                    (values[name] === value || values[name] === value2) &&
                    'col-span-1 pb-16'
                )}
              >
                <label
                  htmlFor={`${uuid}_${index}`}
                  className={cn(
                    'z-index-10 group relative block cursor-pointer bg-silver-25',
                    disabled ||
                      (option?.rules?.disabled &&
                        'cur cursor-not-allowed opacity-50')
                  )}
                  key={index}
                  data-tooltip-id="radio-option-tooltip"
                  data-tooltip-content={option.info}
                  data-tooltip-place="top"
                  data-tooltip-delay-show={250}
                  data-tooltip-delay-hide={150}
                >
                  <input
                    {...register(name, rules)}
                    id={`${uuid}_${index}`}
                    type="radio"
                    value={value}
                    disabled={disabled || option?.rules?.disabled}
                    className="peer absolute h-0 w-0 opacity-0"
                  />
                  {option.info !== undefined ? (
                    <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 bg-primary opacity-0 transition-opacity duration-500 group-hover:opacity-75"></div>
                  ) : null}
                  <div
                    className={cn(
                      'flex h-auto flex-col items-center border-primary peer-checked:border-2 peer-disabled:border-grayscale-25 peer-disabled:opacity-50',
                      className
                    )}
                  >
                    {handleImage(option)}
                    {option.label !== undefined ? (
                      <div
                        className={cn(
                          'flex h-12 w-full items-center justify-center bg-none text-base font-light leading-4'
                        )}
                      >
                        {option.label}
                      </div>
                    ) : null}
                  </div>
                </label>
                {value2 !== false &&
                (values[name] === value || values[name] === value2) ? (
                  <>
                    <label
                      htmlFor={`${uuid2}_${index}`}
                      className="absolute bottom-0"
                    >
                      <input
                        {...register(name, rules)}
                        id={`${uuid2}_${index}`}
                        type="radio"
                        value={typeof value2 === 'string' ? value2 : ''}
                        disabled={disabled || option?.rules?.disabled}
                        className="peer absolute h-0 w-0 opacity-0"
                      />
                      <div
                        className="right-1/2 flex h-12 w-12 translate-x-1/2 items-center justify-center rounded-full border-primary bg-silver-25 peer-checked:border-2"
                        data-tooltip-id="reverse-radio-option-tooltip"
                        data-tooltip-content={t('Spegelvänd')}
                        data-tooltip-place="right"
                        data-tooltip-delay-show={500}
                      >
                        <img src={reverse} />
                      </div>
                    </label>
                    <Tooltip
                      id="reverse-radio-option-tooltip"
                      style={{
                        borderRadius: 0,
                        padding: '16px',
                        fontWeight: 300
                      }}
                    />
                  </>
                ) : null}
              </div>
            )
          })}
          <Tooltip
            id="radio-option-tooltip"
            style={{
              borderRadius: 0,
              width: '178px',
              padding: '16px',
              fontWeight: 300
            }}
          />
        </div>
        <>
          <FieldErrorMessage
            name={name}
            errors={errors}
          />
        </>
      </div>
    )
  }
)

export default Input
