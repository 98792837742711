import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import { cn, getColEnd, getColSpan, getColStart } from '~/utils/tailwind'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/sharp-regular-svg-icons'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string | React.ReactNode
  placeholder?: string
  columnPosition?: {
    start?: number
    span?: number
    end?: number
  }
  width?: string
  className?: string
  isChecked: boolean
  onChange?: () => void
}

const ExternalCheckbox: React.FC<IInputProps> = ({
  name,
  label,
  placeholder,
  disabled,
  columnPosition,
  width,
  className,
  isChecked,
  onChange
}) => {
  const { start, end, span } =
    columnPosition === undefined
      ? { start: false, span: false, end: false }
      : columnPosition

  return (
    <div
      className={cn(
        'relative flex flex-wrap items-start text-base',
        columnPosition === undefined && 'col-span-full',
        typeof start === 'number' && getColStart(start),
        typeof span === 'number' && getColSpan(span),
        typeof end === 'number' && getColEnd(end),
        width !== undefined && width,
        className
      )}
    >
      <div className="flex items-center focus-within:border">
        <input
          name={name}
          id={name}
          type="checkbox"
          placeholder={placeholder}
          disabled={disabled}
          className="absolute h-8 w-8 cursor-pointer opacity-0"
          checked={isChecked}
          onChange={onChange}
        />
        <div
          className={cn(
            'pointer-events-none relative mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-none border border-black bg-white focus-within:border-pink-700'
          )}
        >
          <FontAwesomeIcon
            className="absolute left-[1px] !hidden text-[20px]"
            icon={faCheck}
          />
        </div>
        <label
          htmlFor={name}
          className="cursor-pointer select-none text-base font-light"
        >
          {label}
        </label>
      </div>
    </div>
  )
}

export default ExternalCheckbox
