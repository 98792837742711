import { useEffect, useState } from 'react'
import { solarPanelClient } from '../http/api'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import ExternalInput from './form/fields/ExternalInput'
import { useDebounce } from 'use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faArrowDownToLine,
  faTrashCan
} from '@fortawesome/sharp-regular-svg-icons'
import { HistoryConfiguration } from '../slices/historySlice'
import { StoreState, useBoundStore } from '../store'
import Button from './buttons/Button'
import CartPdfLinks from './cart/CartPdfLinks'
import ProgressBar from './ProgressBar'
import { cn } from '../utils/tailwind'
import ExternalCheckbox from './form/fields/ExternalCheckbox'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

interface HistoryResponse {
  configurations: {
    current_page: number
    data: HistoryConfiguration[]
    total: number
    to: number
  }
}

interface Params {
  search?: string
  orderBy?: string
  order?: 'ASC' | 'DESC'
  page?: number
}

const History = () => {
  const { t } = useTranslation()
  const {
    history,
    setHistory,
    addHistoryConfigurations,
    setShowDialog,
    setDialogActions
  } = useBoundStore((state: StoreState) => ({
    history: state.history,
    setHistory: state.setHistory,
    addHistoryConfigurations: state.addHistoryConfigurations,
    setShowDialog: state.setShowDialog,
    setDialogActions: state.setDialogActions
  }))

  const navigateTo = useNavigate()

  const [showPdfLinks, setShowPdfLinks] = useState<{
    [reference: string]: boolean
  }>({})
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 300)
  const [sorting, setSorting] = useState({
    field: 'updated_at',
    ascending: false
  })
  const [selected, setSelected] = useState<string[]>([])
  const [disableLoadMore, setDisableLoadMore] = useState(false)
  const [disableAddToCart, setDisableAddToCart] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)

  useEffect(() => {
    setDisableLoadMore(true)
    solarPanelClient
      .get('/history')
      .then(handleHistoryResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMore(false)
      })
  }, [])

  useEffect(() => {
    refreshHistory()
  }, [debouncedSearch, sorting])

  const refreshHistory = () => {
    setDisableLoadMore(true)
    const params: Params = {
      orderBy: sorting.field,
      order: sorting.ascending ? 'ASC' : 'DESC'
    }
    if (debouncedSearch.length) {
      params.search = debouncedSearch
    }
    solarPanelClient
      .get('/history', { params })
      .then(handleHistoryResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMore(false)
      })
  }

  const handleHistoryResponse = (res: AxiosResponse<HistoryResponse>) => {
    setHistory({
      configurations: res.data.configurations.data,
      total: res.data.configurations.total,
      loaded: res.data.configurations.to ?? 0,
      page: 1
    })
    setSelected([])
    setDisableLoadMore(false)
    setDisableDelete(false)
  }

  const loadMore = () => {
    setDisableLoadMore(true)
    const params: Params = {
      orderBy: sorting.field,
      order: sorting.ascending ? 'ASC' : 'DESC',
      page: history.page + 1
    }
    if (debouncedSearch.length) {
      params.search = debouncedSearch
    }
    solarPanelClient
      .get('/history', { params })
      .then(handleLoadMoreResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMore(false)
      })
  }

  const handleLoadMoreResponse = (res: AxiosResponse<HistoryResponse>) => {
    addHistoryConfigurations(
      res.data.configurations.data,
      res.data.configurations.total,
      res.data.configurations.to ?? 0,
      res.data.configurations.current_page
    )
    setDisableLoadMore(false)
  }

  const togglePdfLinks = (reference: string) => {
    setShowPdfLinks((prevState) => ({
      ...prevState,
      [reference]: !prevState[reference]
    }))
  }

  const toggleSelectedItem = (reference: string) => {
    if (selected.includes(reference)) {
      setSelected(
        selected.filter((ref) => {
          return ref !== reference
        })
      )
    } else {
      setSelected((prevState) => [...prevState, reference])
    }
  }

  const addToCart = () => {
    setDisableAddToCart(true)
    solarPanelClient
      .post('/add-to-cart', {
        references: selected
      })
      .then(handleAddToCartComplete)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableAddToCart(false)
      })
  }

  const handleAddToCartComplete = () => {
    setShowDialog('HistoryAddedToCartDialog')
    setDialogActions({
      NavigateToCart: () => {
        navigateTo('/cart')
        setShowDialog(false)
      }
    })
    setDisableAddToCart(false)
  }

  const deleteSelectedItems = () => {
    setDisableDelete(true)
    solarPanelClient
      .post('/delete', { references: selected })
      .then(refreshHistory)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableDelete(false)
      })
  }

  return (
    <div className="h-full w-full">
      <div className="mb-16 max-w-[526px] text-base">
        {t(
          'Nedan kan du se dina tidigare konfigurationer. Klicka på en konfiguration för att återuppta den och göra eventuella justeringar.'
        )}
      </div>
      <div className="mb-10 flex w-full justify-between">
        {selected.length > 0 ? (
          <div className="flex gap-4">
            <Button
              onClick={addToCart}
              disabled={disableAddToCart}
            >
              {t('Lägg till i projektkorgen')}
            </Button>
            <Button
              variant="ghost"
              className="flex gap-2"
              onClick={deleteSelectedItems}
              disabled={disableDelete}
            >
              <FontAwesomeIcon icon={faTrashCan} />
              {t('Ta bort')}
            </Button>
          </div>
        ) : null}
        <div className="ml-auto w-1/2">
          <ExternalInput
            name="search"
            placeholder={t('Sök konfiguration...')}
            className="w-full"
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
        </div>
      </div>
      <table className="mb-10 w-full border-collapse">
        <thead>
          <tr className="border-b border-grayscale-25 text-lg font-bold">
            <td className="whitespace-nowrap py-4"></td>
            <td
              className="cursor-pointer whitespace-nowrap py-4"
              onClick={() =>
                setSorting({
                  field: 'conditions_name',
                  ascending: !sorting.ascending
                })
              }
            >
              {t('Namn')}
              <div className="ml-2 inline-block">
                <FontAwesomeIcon
                  icon={
                    sorting.field === 'conditions_name' && !sorting.ascending
                      ? faAngleUp
                      : faAngleDown
                  }
                />
              </div>
            </td>
            <td
              className="cursor-pointer whitespace-nowrap py-4"
              onClick={() =>
                setSorting({
                  field: 'reference',
                  ascending: !sorting.ascending
                })
              }
            >
              {t('Referensnummer')}
              <div className="ml-2 inline-block">
                <FontAwesomeIcon
                  icon={
                    sorting.field === 'reference' && !sorting.ascending
                      ? faAngleUp
                      : faAngleDown
                  }
                />
              </div>
            </td>
            <td className="whitespace-nowrap py-4">{t('Senast ändrad')}</td>
            <td className="whitespace-nowrap py-4"></td>
          </tr>
        </thead>
        <tbody>
          {history.configurations?.length
            ? history.configurations.map(
                (configuration: HistoryConfiguration, key: number) => (
                  <tr
                    key={key}
                    className="border-b border-grayscale-25 text-base"
                  >
                    <td className="px-4">
                      <ExternalCheckbox
                        name={`select_${configuration.reference}`}
                        value={1}
                        isChecked={selected.includes(configuration.reference)}
                        onChange={() => {
                          toggleSelectedItem(configuration.reference)
                        }}
                      />
                    </td>
                    <td className="w-full py-4">
                      <a
                        href={`#/parallel/${configuration.reference}`}
                        className="no-underline hover:underline"
                      >
                        {configuration.name}
                      </a>
                    </td>
                    <td className="min-w-[240px] whitespace-nowrap py-4">
                      {configuration.reference}
                    </td>
                    <td className="min-w-[180px] whitespace-nowrap py-4">
                      {configuration.updatedAt}
                    </td>
                    <td>
                      <div className="relative">
                        <div
                          className={cn(
                            'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-silver-25',
                            showPdfLinks[configuration.reference] &&
                              'bg-silver-25'
                          )}
                          data-tooltip-id={configuration.reference}
                          data-tooltip-content={t('Ladda ner PDF')}
                          data-tooltip-place="right"
                          data-tooltip-delay-show={500}
                          onClick={() =>
                            togglePdfLinks(configuration.reference)
                          }
                        >
                          <FontAwesomeIcon icon={faArrowDownToLine} />
                        </div>
                        {showPdfLinks[configuration.reference] ? (
                          <CartPdfLinks pdfUrl={configuration.pdfUrl} />
                        ) : null}
                        <Tooltip
                          id={configuration.reference}
                          style={{ borderRadius: 0 }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              )
            : null}
        </tbody>
      </table>
      <div className="flex flex-col items-center gap-4 pb-20">
        <div className="text-sm font-medium">{`${t('Visar')} ${
          history.loaded
        } ${t('av')} ${history.total}`}</div>
        <ProgressBar
          progress={(history.loaded / history.total) * 100}
          className="max-w-[244px]"
        />
        {history.loaded < history.total ? (
          <Button
            variant="ghost"
            onClick={loadMore}
            disabled={disableLoadMore}
          >
            {t('Ladda fler konfigurationer')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default History
