import { ReactNode } from 'react'
import { cn } from '../../utils/tailwind'
import BackButton from '../buttons/BackButton'
import { useTranslation } from 'react-i18next'
import { createHashHistory } from 'history'

type Props = {
  children: ReactNode
  className?: string
  hideBackButton?: boolean
}

const PageWrapper = ({ children, className, hideBackButton }: Props) => {
  const { t } = useTranslation()
  const history = createHashHistory()

  return (
    <div
      className={cn(
        'relative flex h-full w-full flex-col items-center',
        className
      )}
    >
      {!hideBackButton ? (
        <BackButton
          className="absolute left-14 top-14"
          onClick={() => {
            history.back()
          }}
        >
          {t('Gå tillbaka')}
        </BackButton>
      ) : null}
      {children}
    </div>
  )
}

export default PageWrapper
