import { StoreState, useBoundStore } from '../store'
import { shallow } from 'zustand/shallow'
import Button from './buttons/Button'

const ImageStatesButtons = () => {
  const {
    panelSectionsImageView,
    positionsOverviewImageView,
    sectionsOverviewImageView,
    setPanelSectionsImageView,
    setPositionsOverviewImageView,
    setSectionsOverviewImageView
  } = useBoundStore(
    (state: StoreState) => ({
      panelSectionImagesData: state.panelSectionImagesData,
      panelSectionsImageView: state.panelSectionsImageView,
      positionsOverviewImageView: state.positionsOverviewImageView,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      setPanelSectionsImageView: state.setPanelSectionsImageView,
      setPositionsOverviewImageView: state.setPositionsOverviewImageView,
      setSectionsOverviewImageView: state.setSectionsOverviewImageView,
      setPanelSectionImagesData: state.setPanelSectionImagesData
    }),
    shallow
  )
  return (
    <div className="absolute bottom-8 right-4 z-50 flex gap-2">
      <Button
        onClick={() => setPanelSectionsImageView(!panelSectionsImageView)}
      >
        panelSectionsImageView {panelSectionsImageView}
      </Button>
      <Button
        onClick={() =>
          setPositionsOverviewImageView(!positionsOverviewImageView)
        }
      >
        positionsOverviewImageView {positionsOverviewImageView}
      </Button>
      <Button
        onClick={() => setSectionsOverviewImageView(!sectionsOverviewImageView)}
      >
        sectionsOverviewImageView {sectionsOverviewImageView}
      </Button>
    </div>
  )
}

export default ImageStatesButtons
