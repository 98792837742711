import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import ErrorIcon from '../ErrorIcon'
import { cn, getColEnd, getColSpan, getColStart } from '~/utils/tailwind'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  info?: string
  icon?: React.ReactElement
  placeholder?: string
  unit?: string
  columnPosition?: {
    start?: number
    span?: number
    end?: number
  }
  hideError?: boolean
  errorMessage?: string
  width?: string
  className?: string
}

const ExternalInput: React.FC<IInputProps> = ({
  name,
  label,
  value,
  info,
  icon,
  type,
  step,
  placeholder,
  disabled,
  unit,
  columnPosition,
  hideError,
  errorMessage,
  width,
  onKeyDown,
  onChange,
  className
}) => {
  const { start, end, span } =
    columnPosition === undefined
      ? { start: false, span: false, end: false }
      : columnPosition

  return (
    <div
      className={cn(
        'relative flex flex-wrap items-start text-base',
        type === 'hidden' && 'hidden',
        columnPosition === undefined && 'col-span-full',
        typeof start === 'number' && getColStart(start),
        typeof span === 'number' && getColSpan(span),
        typeof end === 'number' && getColEnd(end),
        width !== undefined && width,
        className
      )}
    >
      {label && (
        <label
          className="mb-2 flex w-full text-sm font-bold uppercase"
          htmlFor={name}
        >
          {label}
          <span>
            {icon}
            {info}
          </span>
        </label>
      )}
      <div
        className={clsx(
          'relative flex w-full border border-grayscale-25 bg-white focus-within:border-grayscale-75',
          errorMessage?.length && 'border-red-100'
        )}
      >
        <input
          className="flex w-full min-w-0 grow border-none py-[15px] pl-4 leading-4 focus:outline-none" //focus-visible:outline-0
          name={name}
          id={name}
          value={value}
          type={type}
          step={step}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        {unit && (
          <div className="h-100 flex items-center bg-white pr-4">{unit}</div>
        )}
        {errorMessage?.length && !hideError ? <ErrorIcon /> : null}
      </div>
      {!hideError && errorMessage?.length ? (
        <p className="absolute bottom-[-24px] text-[10px] leading-4 text-red-100">
          {errorMessage}
        </p>
      ) : null}
    </div>
  )
}

export default ExternalInput
