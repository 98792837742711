import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import Profile from '../Profile'

const ProfilePage = () => {
  return (
    <PageWrapper
      hideBackButton
      className="w-full flex-col"
    >
      <SideBar />
      <div className="w-full max-w-[1432px] px-72 py-36">
        <Profile />
      </div>
    </PageWrapper>
  )
}

export default ProfilePage
