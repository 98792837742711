import { MouseEvent, ReactNode } from 'react'
import CloseButton from './buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  children: ReactNode
  handleClose: () => void
}

const Modal = ({ children, handleClose }: Props) => {
  const close = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).getAttribute('data-name') === 'parent')
      handleClose()
  }

  return (
    <div
      data-name="parent"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-[15%]"
      onClick={close}
    >
      <div className="flex h-full w-full max-w-[856px] flex-col py-36">
        <div className="relative my-auto w-full overflow-auto bg-white px-28 py-24">
          <CloseButton
            className="absolute right-8 top-8"
            onClick={handleClose}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="text-xl"
            />
          </CloseButton>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
