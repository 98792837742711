import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { useBoundStore } from '../store'
import { drawPanels } from './panelAreaUtils'
import { getRoofPoints, scaleMmToPixels } from '../utils/configurator'
import { getCoordinatesFromPoints } from './utils'

/**
 * Caches a Konva.Node element for improved performance.
 * @param element - The Konva.Node element to be cached.
 * @param pixelRatio - The pixel ratio for the cached image. Default is 1.
 */
export const cacheNode = (element: Konva.Node, pixelRatio = 1) => {
  if (!element.isCached() && element.width() > 0 && element.height() > 0) {
    element.cache({
      pixelRatio,
      imageSmoothingEnabled: true
    })
  }
}

/**
 * Clears the cache of a Konva.Node element if it is cached.
 * @param element - The Konva.Node element to clear the cache for.
 */
export const clearNodeCache = (element: Konva.Node) => {
  if (element && element.isCached()) {
    element.clearCache()
  }
}

/**
 * Calculates the new scale value based on the current scale and the scroll event.
 * @param scale - The current scale value.
 * @param event - The scroll event object.
 * @returns The new scale value.
 */
export const getScrollValue = (
  scale: number,
  event: KonvaEventObject<WheelEvent>
) => {
  //Determine direction by scroll event value is positive or negative
  const direction = event.evt.deltaY > 0 ? 1 : -1
  const shouldIncrease = direction <= 0
  const shouldDecrease = direction > 0
  //Max and mini zoom distance and distance to zoom based on scaleBy
  const maxScale = 10
  const minScale = 0.01
  const scaleBy = 1.1

  let newScale = 1

  if (shouldIncrease) {
    const increasedScale = scale * scaleBy >= 0.01 ? scale * scaleBy : 0.01
    newScale =
      Math.round(
        (increasedScale < maxScale ? increasedScale : maxScale) * 1000
      ) / 1000
  } else if (shouldDecrease) {
    const decreasedScale = scale / scaleBy >= 0.01 ? scale / scaleBy : 0.01
    newScale =
      Math.round(
        (decreasedScale > minScale ? decreasedScale : minScale) * 1000
      ) / 1000
  }

  return newScale
}

/**
 * Resets all configurator states.
 */
export const resetAllConfiguratorStates = () => {
  const state = useBoundStore.getState()

  state.resetConfigurator()
  state.resetConditions()
  state.resetRoof()
  state.resetPanelArea()
  state.resetPanelAreaSection()
  state.resetImage()
  state.setIsLoaded(true)
}

/**
 * Handles the loading of a configuration.
 *
 * @param res - The response containing the configuration data.
 */
export const handleLoadConfiguration = (res: any) => {
  const state = useBoundStore.getState()
  state.setUid(res.data.uid)
  state.setReference(res.data.reference)
  state.updateConditions(res.data.conditions)
  state.updateRoof(res.data.roofs[0])
  const roofPoints = getRoofPoints(res.data.roofs[0].shape, {
    a: res.data.roofs[0].measurementA,
    b: res.data.roofs[0].measurementB,
    c: res.data.roofs[0].measurementC
  })
  const roofCoordinates = getCoordinatesFromPoints(roofPoints)
  state.setRoofCoordinates(roofCoordinates)
  state.setPanelAreas(
    res.data.panelAreas.map((panelArea: PanelArea) => {
      const konvaRect = new Konva.Rect({
        width: scaleMmToPixels(panelArea.size.width),
        height: scaleMmToPixels(panelArea.size.height),
        x: panelArea.position.x,
        y: panelArea.position.y
      })
      panelArea.panels = drawPanels(
        konvaRect,
        panelArea.panelInfo,
        panelArea.uid,
        panelArea.removedPanels,
        roofCoordinates,
        false
      )
      return panelArea
    })
  )
  state.setPanelAreaSections(res.data.panelAreaSections)
  state.setSummary(res.data.summary)
  state.setIsApproved(res.data.isApproved)
  state.updateProducts(res.data.products)
  state.setIsPositionDataValid(true)
  state.setIsRoofMaterialDataValid(true)
  state.setIsRoofPropertiesDataValid(true)
  setTimeout(() => {
    state.setIsConfigurationComplete(true)
    state.setShowResults(true)
  }, 1000)
}

/**
 * Handles the onClick event for the canvas.
 * @param event - The event object containing information about the click event.
 */
export const handleOnClick = (event: KonvaEventObject<MouseEvent>) => {
  const { isDrawing, activeArea, isConfigurationComplete, setActiveArea } =
    useBoundStore.getState()

  if (
    (isDrawing === false && event.target.attrs.name === 'panel-area') ||
    event.target.attrs.name === 'panel'
  ) {
    if (
      activeArea !== event.target.attrs.panelAreaUid &&
      isConfigurationComplete !== true
    ) {
      setActiveArea(event.target.attrs.panelAreaUid)
    }
  } else {
    if (
      !event.target.attrs?.name?.includes('_anchor') &&
      !event.target.attrs?.name?.includes('panel-action')
    ) {
      setActiveArea(null)
    }
  }
}

/**
 * Handles the drag start event for a Konva object.
 * @param event - The drag event object.
 * @param setIsDragging - A function to set the dragging state.
 */
export const handleOnDragStart = (
  event: KonvaEventObject<DragEvent>,
  setIsDragging: (isDragging: boolean) => void,
  setIsDraggingPanelArea: (isDragging: boolean) => void
) => {
  const { activeArea } = useBoundStore.getState()
  if (activeArea === null && event.target.attrs.name !== 'panel-area-group') {
    setIsDragging(true)
  }
  if (event.target.attrs.name === 'panel-area-group') {
    setIsDraggingPanelArea(true)
  }
}

/**
 * Handles the drag end event.
 * @param setIsDragging - A function to set the dragging state.
 */
export const handleOnDragEnd = (
  setIsDragging: (isDragging: boolean) => void,
  setIsDraggingPanelArea: (isDragging: boolean) => void
) => {
  setIsDragging(false)
  setIsDraggingPanelArea(false)
}
