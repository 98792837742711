import Screws from './Screws'
import { Group, Rect } from 'react-konva'
import { scaleMmToPixels, scalePixelsToMm } from '~/utils/configurator'

type Props = {
  position: Position
  railAngle: number
}

const ShortRail = ({ position, railAngle }: Props) => {
  const scale = 1

  const mountLength = 7 * scale
  const mountWidth = 35 * scale
  const stokeWidth = 1
  const size =
    railAngle == 0
      ? { width: mountLength, height: mountWidth }
      : { width: mountWidth, height: mountLength }
  const offset = { x: size.width / 2, y: size.height / 2 }
  const scaledPosition = scaleMmToPixels<Position>(position)
  const screwPositions =
    railAngle == 0
      ? [
          { x: size.width / 2, y: size.width / 2 - stokeWidth },
          { x: (size.width + stokeWidth) / 2, y: size.height / 2 },
          { x: (size.width + stokeWidth) / 2, y: size.height - size.width / 2 }
        ]
      : [
          { x: size.height / 2, y: (size.height + stokeWidth) / 2 },
          { x: size.width / 2, y: (size.height + stokeWidth) / 2 },
          {
            x: size.width - (size.height + stokeWidth) / 2,
            y: (size.height + stokeWidth) / 2
          }
        ]

  return (
    <Group
      width={size.width}
      height={size.height}
      offsetX={offset.x}
      offsetY={offset.y}
      x={scaledPosition.x}
      y={scaledPosition.y}
    >
      <Rect
        width={size.width}
        height={size.height}
        strokeWidth={stokeWidth}
        stroke={'#979797'}
        fill={'#D8D8D8'}
      />
      {screwPositions.map((screwsPosition) => (
        <Screws
          key={`${screwsPosition.x}-${screwsPosition.y}`}
          position={scalePixelsToMm<Position>(screwsPosition)}
          railAngle={railAngle}
          scale={scale}
        />
      ))}
    </Group>
  )
}

export default ShortRail
