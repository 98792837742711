import Konva from 'konva'
import React, { createRef, useEffect, useState } from 'react'
import { Label, Line, Circle, Tag, Text, Group } from 'react-konva'

type Props = {
  distanceLabel: DistanceLabel
  railAngle: number
  isApproved: boolean
}

const DistanceLabel = React.memo(
  ({ distanceLabel, railAngle, isApproved }: Props) => {
    const { showLabel, startPosition, endPosition, value } = distanceLabel

    const background = isApproved ? '#001489' : '#DA0A16'

    const radius = 6 / 2
    const textWidth = 32
    const textHeight = 16
    const labelPosition = { x: 0, y: 0 }

    if (distanceLabel.type === 'mount') {
      labelPosition.x =
        railAngle == 0 ? startPosition.x : startPosition.x + value / 2
      labelPosition.y =
        railAngle == 0 ? startPosition.y + value / 2 : startPosition.y
    } else if (distanceLabel.type === 'rail') {
      labelPosition.x =
        railAngle == 0 ? startPosition.x + value / 2 : startPosition.x
      labelPosition.y =
        railAngle == 0 ? startPosition.y : startPosition.y + value / 2
    }

    const groupRef = createRef<Konva.Group>()
    const [currentGroup, setCurrentGroup] = useState<Konva.Group | null>(null)

    useEffect(() => {
      if (groupRef.current !== null && currentGroup === null) {
        setCurrentGroup(groupRef.current)
      }
    }, [groupRef])

    useEffect(() => {
      if (currentGroup !== null) {
        currentGroup.moveToTop()
      }
    }, [currentGroup])

    return (
      <>
        <Line
          points={[
            startPosition.x,
            startPosition.y,
            endPosition.x,
            endPosition.y
          ]}
          stroke={background}
          strokeWidth={1}
        />
        <Circle
          x={startPosition.x}
          y={startPosition.y}
          radius={radius}
          fill={'#DEE0E0'}
          stroke={'#808080'}
        />
        <Circle
          x={endPosition.x}
          y={endPosition.y}
          radius={radius}
          fill={'#DEE0E0'}
          stroke={'#808080'}
        />
        <Group ref={groupRef}>
          <Label
            visible={showLabel}
            x={labelPosition.x}
            y={labelPosition.y}
            offsetY={textHeight / 2}
            offsetX={textWidth / 2}
          >
            <Tag fill={background} />
            <Text
              fontSize={10}
              text={Math.abs(value).toString()}
              width={textWidth}
              height={textHeight}
              align="center"
              verticalAlign="middle"
              fill="#fff"
              fontFamily="Roboto"
              fontStyle="bold"
            />
          </Label>
        </Group>
      </>
    )
  }
)

export default DistanceLabel
