import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/sharp-regular-svg-icons'
import { cn } from '../../utils/tailwind'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const Button: React.FC<IButtonProps> = ({
  type,
  children,
  disabled,
  onClick,
  className
}) => {
  return (
    <button
      className={cn(
        'min-w-[100px] text-base font-medium text-black',
        className
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <div className="mr-2 inline-block text-[16px] leading-none">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      {children}
    </button>
  )
}

export default Button
