import ShowPlan from './canvas/ShowPlan'
import Results from './Results'
import ImageHandler from './ImageHandler'
import ImageStatesButtons from './ImageStatesButtons'
import SectionImageHandler from './canvas/konva/image/SectionImageHandler'
import Canvas from './canvas/konva/Canvas'
import DrawerPanelSettings from './DrawerPanelSettings'
import DrawerConditions from './DrawerConditions'
import Compass from './canvas/Compass'
import CloseConfigurator from './CloseConfigurator'
import AddPanelArea from './canvas/AddPanelArea'

const Configurator = () => {
  return (
    <div className="fixed flex h-full flex-wrap bg-silver-50">
      <Canvas />
      <DrawerConditions />
      <DrawerPanelSettings />
      <Compass />
      <ShowPlan />
      <Results />
      <AddPanelArea />
      <ImageHandler />
      <SectionImageHandler />
      <CloseConfigurator />
      {import.meta.env.VITE_DEBUG_IMAGES === 'true' ? (
        <ImageStatesButtons />
      ) : null}
    </div>
  )
}

export default Configurator
