import { createRef, useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { Layer, Stage } from 'react-konva'
import Roof from '~/components/canvas/konva/Roof'
import { getRoofPoints } from '~/utils/configurator'
import Konva from 'konva'
import { cn } from '~/utils/tailwind'

type Props = {
  onRefSet: (ref: Konva.Stage) => void
}

const RoofImageStage = ({ onRefSet }: Props) => {
  const [stageSize, setStageSize] = useState<Size>()
  const [points, setPoints] = useState<number[]>([])
  const [minX, setMinX] = useState(0)
  const stageRef = createRef<Konva.Stage>()

  const {
    roof,
    scale,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      scale: state.roofImageScale,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.isRoofPropertiesDataValid
    }),
    shallow
  )

  const padding = 1 / scale

  useEffect(() => {
    const { shape, measurementA, measurementB, measurementC = 0 } = roof
    if (shape !== '' && measurementA > 0 && measurementB > 0) {
      setPoints(
        getRoofPoints(shape, {
          a: measurementA,
          b: measurementB,
          c: measurementC
        })
      )
    }
  }, [roof])

  useEffect(() => {
    if (points.length > 0) {
      const xPoints: number[] = []
      const yPoints: number[] = []

      points.forEach((number, index) => {
        if (index % 2 === 0) {
          xPoints.push(number)
        } else {
          yPoints.push(number)
        }
      })

      const maxX = Math.max(...xPoints)
      const minX = Math.min(...xPoints)
      const maxY = Math.max(...yPoints)
      const minY = Math.min(...yPoints)
      const width = maxX - minX
      const height = maxY - minY

      setMinX(minX)
      setStageSize({ width, height })
    }
  }, [points])

  useEffect(() => {
    if (stageRef.current) {
      onRefSet(stageRef.current)
    }
  }, [stageRef, onRefSet])

  if (
    stageSize === undefined ||
    !(
      isPositionDataValid &&
      isRoofMaterialDataValid &&
      isRoofPropertiesDataValid
    )
  ) {
    return
  }

  return (
    <Stage
      ref={stageRef}
      width={stageSize.width + padding * 2}
      height={stageSize.height + padding * 2}
      className={cn(
        'absolute top-[99999px]',
        import.meta.env.VITE_DEBUG_IMAGES === 'true' && 'top-0 z-50'
      )}
    >
      <Layer id="roof">
        <Roof
          position={{ x: padding - minX, y: padding }}
          scale={1}
          imageView={true}
          isScrolling={false}
          isDragging={false}
        />
      </Layer>
    </Stage>
  )
}

export default RoofImageStage
