import { ReactNode } from 'react'
import CloseButton from './buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/sharp-regular-svg-icons'
import { cn } from '../utils/tailwind'

type Props = {
  children: ReactNode
  isOpen: boolean
  handleClose: () => void
  className?: string
  hideCloseButton?: boolean
}

const Drawer = ({
  children,
  isOpen,
  handleClose,
  className,
  hideCloseButton
}: Props) => {
  return (
    <div
      className={cn(
        'fixed bottom-0 left-[-640px] top-0 z-10 flex w-full max-w-[640px] flex-col overflow-y-auto bg-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-[400ms]',
        isOpen && 'left-[120px]',
        className
      )}
    >
      {hideCloseButton !== true ? (
        <CloseButton
          className="mb-2 ml-auto mr-4 mt-8 flex-shrink-0"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      ) : null}
      <div className="px-[80px] pb-24">{children}</div>
    </div>
  )
}

export default Drawer
