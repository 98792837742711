import i18n from './i18n'
const { t } = i18n
import { useBoundStore } from '../store'
import { solarPanelClient } from '../http/api'
import { AxiosResponse } from 'axios'
import { getPanelAreasForAPI } from './panelAreaUtils'

export const adjustToClosestMultipleOf25 = (
  value: number,
  increase: boolean
) => {
  const remainder = Math.abs(value) % 25
  const closestMultiple = Math.floor(Math.abs(value) / 25) * 25
  const isMultipleOf25 = remainder === 0
  if (increase) {
    if (isMultipleOf25) {
      return value + 25
    } else {
      return value < 0 ? -closestMultiple : closestMultiple + 25
    }
  } else {
    if (isMultipleOf25) {
      return value - 25
    } else {
      return value < 0 ? -closestMultiple : closestMultiple
    }
  }
}

/**
 * Rounds a number to the specified precision.
 *
 * @param value - The number to round.
 * @param precision - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

/**
 * Returns largest number in array with an even index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMaxOfEvenIndex = (array: number[]): number => {
  const evenIndices = array.filter((_, index) => index % 2 === 0)
  return Math.max(...evenIndices)
}

/**
 * Returns largest number in array with an odd index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMaxOfOddIndex = (array: number[]): number => {
  const oddIndices = array.filter((_, index) => index % 2)
  return Math.max(...oddIndices)
}

/**
 * Returns smallest number in array with an even index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMinOfEvenIndex = (array: number[]): number => {
  const evenIndices = array.filter((_, index) => index % 2 === 0)
  return Math.min(...evenIndices)
}

/**
 * Returns smallest number in array with an odd index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMinOfOddIndex = (array: number[]): number => {
  const oddIndices = array.filter((_, index) => index % 2)
  return Math.min(...oddIndices)
}

/**
 * Returns roof image scale used for sizing the labels based on image
 * size (920x550) plus the spacing for the distance labels (40px).
 *
 * @param roofWidth {number} total width of the roof
 * @returns {number}
 */
export const getPositionImageScale = (roofWidth: number) => {
  return 920 / roofWidth < 1 ? 920 / roofWidth : 1
}

export const getRoofName = (type: string) => {
  switch (type) {
    case 'gable':
      return t('Sadel')
      break
    case 'pent':
      return t('Pulpet')
      break
    case 'butterfly':
      return t('Motfalls')
      break
  }
}

/**
 * Saves the configuration by sending a POST request to the server.
 * @param handleResponse - A callback function to handle the response from the server.
 */
export const saveConfiguration = (
  handleResponse: (res: AxiosResponse) => void
) => {
  const { uid, roof, conditions, panelAreas, panelAreaSections } =
    useBoundStore.getState()
  solarPanelClient
    .post('/save', {
      uid,
      roofs: [roof],
      conditions,
      panelAreas: getPanelAreasForAPI(panelAreas),
      panelAreaSections
    })
    .then(handleResponse)
}

/**
 * Converts an array of points into an array of coordinates.
 * Each point in the input array represents an (x, y) coordinate pair.
 * The resulting array contains arrays of coordinates, where each coordinate pair is multiplied by 10 (mm).
 *
 * @param points - An array of points representing (x, y) coordinate pairs.
 * @returns An array of coordinates, where each coordinate pair in millimeter scale.
 */
export const getCoordinatesFromPoints = (points: number[]): number[][] => {
  const coordinates: number[][] = []
  for (let i = 0; i < points.length; i += 2) {
    coordinates.push([points[i] * 10, points[i + 1] * 10])
  }
  return coordinates
}
