import { z } from 'zod'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './fields/Input'
import Button from '../buttons/Button'
import { zodI18nMap } from 'zod-i18n-map'
import { profileClient } from '~/http/api'
import { StoreState, useBoundStore } from '~/store'
import { useTranslation } from 'react-i18next'

const validationSchema = z.object({
  current_password: z.string().min(1),
  password: z.string().min(1),
  password_confirmation: z.string().min(1)
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const FormChangePassword = () => {
  const { t } = useTranslation()
  const { setShowDialog } = useBoundStore((state: StoreState) => ({
    setShowDialog: state.setShowDialog
  }))

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema)
  })

  const {
    handleSubmit,
    setError,
    reset,
    formState: { isDirty }
  } = form

  const handleApiResponse = (res: any) => {
    if (res.data.status === true) {
      setShowDialog('PasswordChanged')
      reset()
    }
  }

  const handleError = (res: any) => {
    const errors = res.response.data.errors
    Object.keys(errors).forEach((error) => {
      setError(error as keyof ValidationSchema, { message: errors[error] })
    })
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    profileClient
      .post('change-password', data)
      .then(handleApiResponse)
      .catch(handleError)
  }

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mb-6 grid grid-cols-2 flex-col gap-6"
        >
          <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
            {t('Byt lösenord')}
          </h3>
          <Input
            label={t('Lösenord') || ''}
            name="current_password"
            type="password"
            className="col-span-2"
          />
          <Input
            label={t('Nytt lösenord') || ''}
            name="password"
            type="password"
            className="col-span-1"
          />
          <Input
            label={t('Repetera nytt lösenord') || ''}
            name="password_confirmation"
            type="password"
            className="col-span-1"
          />
          <div className="col-span-full flex justify-end">
            <Button
              disabled={!isDirty}
              variant="ghost"
            >
              {t('Byt lösenord')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default FormChangePassword
