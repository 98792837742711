import { Group, Label, Tag, Text, Line } from 'react-konva'
import { StoreState, useBoundStore } from '~/store'
import { scaleMmToPixels } from '~/utils/configurator'
import { useTranslation } from 'react-i18next'

interface Props extends EdgeZone {
  imageView?: boolean
  scale: number
}

const EdgeZone = ({ imageView, coordinates, scale }: Props) => {
  const { t } = useTranslation()
  // return

  const points = coordinates
    .map((coordinate) => [coordinate.x / 10, coordinate.y / 10])
    .flat()

  const {
    sectionsOverviewImageView,
    positionsOverviewImageView,
    panelSectionsImageView
  } = useBoundStore((state: StoreState) => ({
    sectionsOverviewImageView: state.sectionsOverviewImageView,
    positionsOverviewImageView: state.positionsOverviewImageView,
    panelSectionsImageView: state.panelSectionsImageView
  }))

  const stroke = '#BE8940'
  const strokeWidth = positionsOverviewImageView ? 3 / scale : 2 / scale
  const textWidth = 60 / scale
  const textHeight = 24 / scale
  const fontSize = 10 / scale
  const minX = Math.min(...coordinates.map((coordinate) => coordinate.x))
  const maxX = Math.max(...coordinates.map((coordinate) => coordinate.x))
  const maxY = Math.max(...coordinates.map((coordinate) => coordinate.y))
  const horizontalLabelPosition = scaleMmToPixels<number>(
    minX + (maxX - minX) / 2
  )
  const verticalLabelPosition = scaleMmToPixels<number>(maxY - textHeight / 2)

  if (
    (imageView === true &&
      (sectionsOverviewImageView !== false ||
        panelSectionsImageView !== false)) ||
    points.length === 0
  )
    return null

  return (
    <Group>
      <Line
        points={points}
        stroke={stroke}
        strokeWidth={strokeWidth}
        dash={[8, 8]}
        dashEnabled={false}
        name="edge-zone"
        closed
      />
      {imageView !== true && positionsOverviewImageView === false && (
        <Label
          x={horizontalLabelPosition}
          y={verticalLabelPosition}
          offset={{
            x: textWidth / 2,
            y: textHeight / 2
          }}
        >
          <Tag fill={stroke} />
          <Text
            fontSize={fontSize}
            text={t('Randzon')}
            width={textWidth}
            height={textHeight}
            align="center"
            verticalAlign="middle"
            fill="#FFF"
            fontFamily="Roboto"
          />
        </Label>
      )}
    </Group>
  )
}

export default EdgeZone
