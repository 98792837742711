import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig
} from 'axios'
import Cookies from 'js-cookie'
import { useBoundStore } from '../store'

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.retryCount === undefined) {
    config.retryCount = 3
  }
  if (
    config.method &&
    ['post', 'put', 'delete'].includes(config.method) &&
    !Cookies.get('XSRF-TOKEN')
  ) {
    return setCSRFToken().then(() => config)
  }
  return config
}

const setCSRFToken = async (retryCount?: number) => {
  return authClient.get('/csrf-cookie', { retryCount: retryCount ?? 3 })
}

const handleError = async (error: AxiosError, instance: AxiosInstance) => {
  const { config } = error
  const { user, setShowDialog } = useBoundStore.getState()
  if (
    (error.code === 'ERR_NETWORK' && error.response === undefined) ||
    error.response?.status === 419
  ) {
    if (!config || !config.retryCount) {
      setShowDialog('ErrorDialog')
      throw error
    }
    if (user !== null && config?.url !== '/login') {
      setShowDialog('LoginDialog')
      // return unresolved promise to stop execution
      return new Promise(() => {})
    }
    config.retryCount -= 1
    await setCSRFToken(config.retryCount)
    return instance.request(error.config ?? {})
  } else if (error.response?.status === 401 && config?.url !== '/login') {
    setShowDialog('UnauthorizedDialog')
  } else if (
    error.response?.status !== undefined &&
    error.response.status >= 400 &&
    error.response.status !== 401
  ) {
    setShowDialog('ErrorDialog')
  }
  throw error
}

const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
    withCredentials: true
  })
  instance.interceptors.request.use(requestInterceptor, null)
  instance.interceptors.response.use(
    (response) => response,
    (error) => handleError(error, instance)
  )
  return instance
}

export const authClient = createAxiosInstance(
  import.meta.env.VITE_API_AUTH_BASE_URL
)

export const solarPanelClient = createAxiosInstance(
  import.meta.env.VITE_API_SOLAR_PANELS_BASE_URL
)

export const profileClient = createAxiosInstance(
  import.meta.env.VITE_API_PROFILE_BASE_URL
)
