import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { cn } from '../../utils/tailwind'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const Button: React.FC<IButtonProps> = ({
  type,
  children,
  disabled,
  variant,
  onClick,
  className
}) => {
  return (
    <button
      className={cn(
        className,
        'box-border h-12 min-w-[100px] bg-primary px-6 py-4 text-base font-medium leading-4 text-white hover:bg-primary-hover disabled:cursor-not-allowed disabled:bg-primary-disabled',
        variant === 'ghost' &&
          'border border-grayscale-75 bg-transparent text-grayscale-75 hover:bg-grayscale-75 hover:text-white disabled:text-white',
        variant === 'link' &&
          'w-full bg-transparent font-light text-grayscale-75 underline hover:bg-transparent hover:text-black'
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
