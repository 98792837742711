/**
 * Returns image element used has helper function
 * to get correctly typed element.
 *
 * @param src {string} Image
 * @returns {HTMLImageElement}
 */
export const getImageElement = (src: string): HTMLImageElement => {
  const image = new window.Image()
  image.src = src
  return image
}

export const scalePosition = (position: Position) => {
  return {
    x: Math.round(position.x * 10),
    y: Math.round(position.y * 10)
  }
}

export const rescalePosition = (position: Position) => {
  return {
    x: Math.round(position.x / 10),
    y: Math.round(position.y / 10)
  }
}

export const scaleObjectValues = <T>(object: Record<string, number>) => {
  Object.keys(object).forEach((key) => {
    object[key] = Math.round(object[key] * 10)
  })
  return object as T
}

export const rescaleObjectValues = <T>(object: Record<string, number>) => {
  Object.keys(object).forEach((key) => {
    object[key] = Math.round(object[key] / 10)
  })
  return object as T
}

export const scalePixelsToMm = <T>(object: Record<string, number> | number) => {
  if (typeof object === 'number') {
    return Math.round(object * 10) as T
  }
  const scaledObject = { ...object }
  Object.keys(scaledObject).forEach((key) => {
    scaledObject[key] = Math.round(scaledObject[key] * 10)
  })
  return scaledObject as T
}

export const scaleMmToPixels = <T>(object: Record<string, number> | number) => {
  if (typeof object === 'number') {
    return Math.round(object / 10) as T
  }
  const scaledObject = { ...object }
  Object.keys(scaledObject).forEach((key) => {
    scaledObject[key] = Math.round(scaledObject[key] / 10)
  })
  return scaledObject as T
}

export const getSectionLabelText = (
  uid: string,
  panelAreaUid: string,
  panelAreas: PanelArea[],
  panelAreaSections: PanelAreaSection[]
) => {
  const panelAreaSectionIndex = panelAreaSections
    .filter(
      (panelAreaSection) => panelAreaSection.panelAreaUid === panelAreaUid
    )
    .findIndex((panelAreaSection) => panelAreaSection.uid === uid)
  return `${
    panelAreas.findIndex((panelArea) => panelArea.uid === panelAreaUid) + 1
  }.${panelAreaSectionIndex + 1}`
}

export const getRoofPoints = (
  shape: string,
  measurements: { a: number; b: number; c: number }
): number[] => {
  const scaledMeasurements = scaleMmToPixels<{
    a: number
    b: number
    c: number
  }>(measurements)

  let offsetAngledLength = 0

  if (
    (shape == 'rhomb1' || shape == 'rhomb2') &&
    scaledMeasurements.c !== undefined
  ) {
    offsetAngledLength = Math.sqrt(
      Math.pow(scaledMeasurements.c, 2) - Math.pow(scaledMeasurements.b, 2)
    )
  }

  switch (shape) {
    case 'rectangle':
    case 'angle1':
      return [
        0,
        0,
        scaledMeasurements.a,
        0,
        scaledMeasurements.c ? scaledMeasurements.c : scaledMeasurements.a,
        scaledMeasurements.b,
        0,
        scaledMeasurements.b
      ]
      break
    case 'angle2':
      return [
        0,
        0,
        scaledMeasurements.a,
        0,
        scaledMeasurements.a,
        scaledMeasurements.b,
        scaledMeasurements.a - scaledMeasurements.c,
        scaledMeasurements.b
      ]
      break
    case 'rhomb1':
      return [
        0,
        0,
        scaledMeasurements.a,
        0,
        scaledMeasurements.a + offsetAngledLength,
        scaledMeasurements.b,
        offsetAngledLength,
        scaledMeasurements.b
      ]
      break
    case 'rhomb2':
      return [
        offsetAngledLength,
        0,
        scaledMeasurements.a + offsetAngledLength,
        0,
        scaledMeasurements.a,
        scaledMeasurements.b,
        0,
        scaledMeasurements.b
      ]
      break
    case 'trapezoid':
      offsetAngledLength = (scaledMeasurements.a - scaledMeasurements.c) / 2
      return [
        0,
        0,
        scaledMeasurements.a,
        0,
        scaledMeasurements.c + offsetAngledLength,
        scaledMeasurements.b,
        offsetAngledLength,
        scaledMeasurements.b
      ]
      break
    default:
      console.log('Something is wrong. Invalid roof shape.')
      return []
      break
  }
}

export const closeConfigurator = () => {
  document.dispatchEvent(new Event('close-configurator'))
}
