import Rail from '../mounting/Rail'
import Mount from '../mounting/Mount'
import { StoreState, useBoundStore } from '~/store'
import Screws from '../mounting/Screws'
import ShortRail from '../mounting/ShortRail'
import { shallow } from 'zustand/shallow'
import React from 'react'

type Props = {
  panelAreaSection: PanelAreaSection
  scale: number
  imageView?: boolean
}

const PanelAreaSection = React.memo(
  ({ panelAreaSection, imageView }: Props) => {
    const scale = 1
    const { roof, activeArea, panelAreas, positionsOverviewImageView } =
      useBoundStore(
        (state: StoreState) => ({
          roof: state.roof,
          panelAreas: state.panelAreas,
          sectionsOverviewImageView: state.sectionsOverviewImageView,
          positionsOverviewImageView: state.positionsOverviewImageView,
          activeArea: state.activeArea
        }),
        shallow
      )

    const panelArea = panelAreas.find(
      (panelArea) => panelArea.uid === panelAreaSection.panelAreaUid
    )

    if (panelArea === undefined) return

    if ((imageView === true && positionsOverviewImageView) || activeArea)
      return null

    return (
      <>
        {roof.attachment !== 'short_rail'
          ? panelAreaSection.result.rails.map((rail) => (
              <Rail
                key={rail.uid}
                rail={rail}
                scale={scale}
              />
            ))
          : null}
        {roof.attachment !== 'long_rail' && roof.attachment !== 'short_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <Mount
                key={index}
                mount={mount}
                railAngle={panelArea.railAngle}
              />
            ))
          : null}
        {roof.attachment === 'long_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <Screws
                key={index}
                position={mount.position}
                railAngle={panelArea.railAngle}
                scale={scale}
              />
            ))
          : null}
        {roof.attachment === 'short_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <ShortRail
                key={index}
                position={mount.position}
                railAngle={panelArea.railAngle}
              />
            ))
          : null}
      </>
    )
  }
)

export default PanelAreaSection
