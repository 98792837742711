import { useState } from 'react'
import UtilizationRates from './UtilizationRates'
import ProductSpecification from './ProductSpecification'
import { cn } from '../utils/tailwind'
import Summary from './Summary'
import { StoreState, useBoundStore } from '../store'
import { useTranslation } from 'react-i18next'

const ResultsTabs = () => {
  const { t } = useTranslation()
  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div className="mb-10 flex flex-auto flex-col overflow-hidden">
      <div className="flex">
        <div
          className={cn(
            'w-1/3 cursor-pointer pb-5 text-center text-lg font-bold',
            selectedTab === 0 && 'border-b-4 border-grayscale-75'
          )}
          onClick={() => {
            setSelectedTab(0)
          }}
        >
          {t('Sammanfattning')}
        </div>
        {user ? (
          <div
            className={cn(
              'w-1/3 cursor-pointer pb-5 text-center text-lg font-bold',
              selectedTab === 1 && 'border-b-4 border-grayscale-75'
            )}
            onClick={() => {
              setSelectedTab(1)
            }}
          >
            {t('Utnyttjandegrader')}
          </div>
        ) : null}
        <div
          className={cn(
            'w-1/3 cursor-pointer pb-5 text-center text-lg font-bold',
            selectedTab === 2 && 'border-b-4 border-grayscale-75'
          )}
          onClick={() => {
            setSelectedTab(2)
          }}
        >
          {t('Artikelspecifikation')}
        </div>
      </div>
      <div className="h-full overflow-auto border border-grayscale-25">
        {selectedTab === 0 ? <Summary /> : null}
        {selectedTab === 1 && user ? (
          <div className="px-10 py-14">
            <UtilizationRates />
          </div>
        ) : null}
        {selectedTab === 2 ? (
          <div className="px-10 py-14">
            <ProductSpecification />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ResultsTabs
