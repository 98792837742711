import { StoreState, useBoundStore } from '../store'
import ProductList from './ProductList'

const ProductSpecification = () => {
  const { products } = useBoundStore((state: StoreState) => ({
    products: state.products
  }))

  return <ProductList products={products} />
}

export default ProductSpecification
