import { z } from 'zod'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './fields/Input'
import Button from '../buttons/Button'
import Checkbox from './fields/Checkbox'
import { zodI18nMap } from 'zod-i18n-map'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from './fields/Select'
import Modal from '../Modal'
import { authClient } from '../../http/api'
import { Trans } from 'react-i18next'
import i18n from '../../lib/i18n'
import * as Sentry from '@sentry/react'
const { t } = i18n

const validationSchema = z.object({
  firstName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  lastName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  email: z.string().email(),
  phone: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  address: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  zip: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  city: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  companyName: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  companyRole: z.string().min(1),
  accept: z.coerce.boolean().refine((bool) => bool == true, {
    message: t('Du måste godkänna regler och villkor.') || ''
  }),
  marketing: z.boolean(),
  password: z.string().min(1),
  password_confirmation: z.string().min(1),
  loginUrl: z.string()
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const Register = () => {
  const navigateTo = useNavigate()
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [showTerms, setShowTerms] = useState(false)

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      companyRole: undefined,
      loginUrl: window.location.origin,
      marketing: false
    }
  })

  const { handleSubmit, setError, watch } = form

  const email = watch('email')

  const handleApiResponse = (res: any) => {
    navigateTo(`/verify/${encodeURIComponent(res.data.reSendLink)}/${email}`)
  }

  const handleApiErrorResponse = (res: any) => {
    Sentry.captureException(res)
    const errors: { [key: string]: string } = res.response.data.errors
    if (typeof errors === 'object') {
      Object.keys(errors).forEach((errorKey: string) => {
        setError(errorKey as keyof ValidationSchema, {
          type: 'string',
          message: errors[errorKey][0]
        })
      })
    }
    setDisableSubmit(false)
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    setDisableSubmit(true)
    authClient
      .post('/register', data)
      .then(handleApiResponse)
      .catch(handleApiErrorResponse)
  }

  return (
    <>
      <div className="w-[896px] px-5 py-24">
        <h1 className="heading-l mb-6">{t('Registrera dig')}</h1>
        <p className="mb-6 max-w-[636px]">
          {t(
            'Registrera dig som användare för att börja använda Welands konfigurator. Är du redan registrerad? '
          )}
          <Link to="/">{t('Logga in här.')}</Link>
        </p>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mb-10 mb-6 flex grid grid-cols-2 flex-col gap-6"
          >
            <Input
              label={t('Förnamn') || ''}
              name="firstName"
              type="text"
              className="col-span-1"
              placeholder={t('Ditt förnamn') || ''}
            />
            <Input
              label={t('Efternamn') || ''}
              name="lastName"
              type="text"
              className="col-span-1"
              placeholder={t('Ditt efternamn') || ''}
            />
            <Input
              label={t('E-Postadress') || ''}
              name="email"
              type="email"
              placeholder="user@example.com"
              className="col-span-1"
            />
            <Input
              label={t('Telefonnummer') || ''}
              name="phone"
              type="text"
              className="col-span-1"
            />
            <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
              {t('Adress')}
            </h3>
            <Input
              label={t('Gatuadress') || ''}
              name="address"
              type="text"
              placeholder={t('Din gatuadress') || ''}
              className="col-span-full"
            />
            <Input
              label={t('Postnummer') || ''}
              name="zip"
              type="text"
              placeholder={t('Postnummer') || ''}
              className="col-span-1"
            />
            <Input
              label={t('Postort') || ''}
              name="city"
              type="text"
              placeholder={t('Ort') || ''}
              className="col-span-1"
            />
            <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
              {t('Företag')}
            </h3>
            <Input
              label={t('Företag') || ''}
              name="companyName"
              type="text"
              placeholder={t('Namn på företag') || ''}
              className="col-span-1"
            />
            <Select
              label={t('Yrkesroll') || ''}
              name="companyRole"
              placeholder={t('Välj alternativ') || ''}
              className="col-span-1"
              options={[
                { value: t('Projektör') || '', label: t('Projektör') || '' },
                { value: t('Montör') || '', label: t('Montör') || '' },
                { value: t('Inköpare') || '', label: t('Inköpare') || '' },
                { value: t('Säljare') || '', label: t('Säljare') || '' },
                { value: t('Övrigt') || '', label: t('Övrigt') || '' }
              ]}
            />
            <h3 className="col-span-full mb-[-8px] mt-2 w-full text-lg font-bold">
              {t('Välj ditt lösenord')}
            </h3>
            <Input
              label={t('Lösenord') || ''}
              name="password"
              type="password"
              className="col-span-1"
            />
            <Input
              label={t('Repetera lösenord') || ''}
              name="password_confirmation"
              type="password"
              className="col-span-1"
            />
            <Checkbox
              name="accept"
              label={
                (
                  <>
                    {t('Jag godkänner ')}
                    <Link
                      to=""
                      onClick={() => setShowTerms(true)}
                    >
                      {t('regler och villkor')}
                    </Link>
                  </>
                ) || ''
              }
              className="mt-2"
            />
            <Checkbox
              name="marketing"
              label={
                t('Jag godkänner att marknadsföring skickas till mig') || ''
              }
              className="mb-4"
            />
            <Input
              name="loginUrl"
              type="hidden"
            />
            <div className="col-span-full flex justify-end">
              <Button disabled={disableSubmit}>{t('Skapa konto')}</Button>
            </div>
          </form>
        </FormProvider>
      </div>
      {showTerms ? (
        <Modal handleClose={() => setShowTerms(false)}>
          <Trans i18nKey="register_terms_modal">
            <h2 className="heading-l mb-6">Regler och villkor</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
              auctor ornare leo, non suscipit magna interdum eu. Curabitur
              pellentesque nibh nibh, at maximus ante fermentum sit amet.
              <br></br>
              <br></br>
              Pellentesque commodo lacus at sodales sodales. Quisque sagittis
              orci ut diam condimentum, vel euismod erat placerat. In iaculis
              arcu eros, eget tempus orci facilisis id. Praesent lorem orci,
              mattis non efficitur id, ultricies vel nibh. Sed volutpat lacus
              vitae gravida viverra. Fusce vel tempor elit. Proin tempus, magna
              id scelerisque vestibulum, nulla ex pharetra sapien, tempor
              posuere massa neque nec felis.
              <br></br>
              <br></br>
              Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper
              dui. Curabitur at risus sodales, tristique est id, euismod justo.
              Mauris nec leo non libero sodales lobortis. Quisque a neque
              pretium, dictum tellus vitae, euismod neque. Nulla facilisi.
              Phasellus ultricies dignissim nibh ut cursus. Nam et quam sit amet
              turpis finibus maximus tempor eget augue. Aenean at ultricies
              lorem. Sed egestas ligula tortor, sit amet mattis ex feugiat non.
              Duis purus diam, dictum et ante at, commodo iaculis urna. Aenean
              lacinia, nisl id vehicula condimentum, enim massa.
              <br></br>
              <br></br>
              Duis purus diam, dictum et ante at, commodo iaculis urna. Aenean
              lacinia, nisl id vehicula condimentum, enim massa.
            </p>
          </Trans>
        </Modal>
      ) : null}
    </>
  )
}

export default Register
