import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ToolbarButton from '~/components/buttons/ToolbarButton'
import { faBorderAll, faPen } from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

const CanvasToolbar = React.memo(() => {
  const { t } = useTranslation()
  const {
    showConditions,
    showPanelSettings,
    setShowConditions,
    setShowPanelSettings
  } = useBoundStore(
    (state: StoreState) => ({
      showConditions: state.showConditions,
      showPanelSettings: state.showPanelSettings,
      setShowConditions: state.setShowConditions,
      setShowPanelSettings: state.setShowPanelSettings
    }),
    shallow
  )

  const [isPanelSettinsButtonActive, setIsPanelSettinsButtonActive] =
    useState(false)
  const [isConditionsButtonActive, setIsConditionsButtonActive] =
    useState(false)

  const toggleShowConditions = (show: boolean) => {
    if (!show) {
      setIsConditionsButtonActive(true)
      if (showPanelSettings) {
        setShowPanelSettings(false)
        setIsPanelSettinsButtonActive(false)
        setTimeout(() => {
          setShowConditions(true)
        }, 450)
        return
      }
      setShowConditions(true)
    } else {
      setIsConditionsButtonActive(false)
      setShowConditions(false)
    }
  }

  const toggleShowPanelSettings = (show: boolean) => {
    if (!show) {
      setIsPanelSettinsButtonActive(true)
      if (showConditions) {
        setShowConditions(false)
        setIsConditionsButtonActive(false)
        setTimeout(() => {
          setShowPanelSettings(true)
        }, 450)
        return
      }
      setShowPanelSettings(true)
    } else {
      setIsPanelSettinsButtonActive(false)
      setShowPanelSettings(false)
    }
  }

  useEffect(() => {
    if (isConditionsButtonActive && !showConditions) {
      setIsConditionsButtonActive(false)
    }
    if (showConditions) {
      setIsConditionsButtonActive(true)
    }
  }, [showConditions])

  useEffect(() => {
    if (isPanelSettinsButtonActive && !showPanelSettings) {
      setIsPanelSettinsButtonActive(false)
    }
    if (showPanelSettings) {
      setIsPanelSettinsButtonActive(true)
    }
  }, [showPanelSettings])

  return (
    <div className="z-20 flex w-[120px] flex-col items-center gap-6 bg-grayscale-75 py-14">
      <ToolbarButton
        onClick={() => toggleShowConditions(showConditions)}
        toolTipText={t('Förutsättningar') || ''}
        active={isConditionsButtonActive}
      >
        <span className="text-xl">
          <FontAwesomeIcon icon={faPen} />
        </span>
      </ToolbarButton>
      <ToolbarButton
        onClick={() => toggleShowPanelSettings(showPanelSettings)}
        toolTipText={t('Panelinställningar') || ''}
        active={isPanelSettinsButtonActive}
      >
        <span className="text-xl leading-none">
          <FontAwesomeIcon icon={faBorderAll} />
        </span>
      </ToolbarButton>
    </div>
  )
})

export default CanvasToolbar
