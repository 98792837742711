import { useTranslation } from 'react-i18next'
import ExternalCheckbox from './fields/ExternalCheckbox'
import { cn } from '~/utils/tailwind'
import Button from '../buttons/Button'
import ExternalInput from './fields/ExternalInput'
import { useEffect, useState } from 'react'
import { solarPanelClient } from '~/http/api'
import { StoreState, useBoundStore } from '~/store'
import { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine } from '@fortawesome/sharp-regular-svg-icons'
import { saveConfiguration } from '~/lib/utils'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { shallow } from 'zustand/shallow'
import * as Sentry from '@sentry/react'

interface SaveResponse {
  reference?: string
  pdfUrl?: string
}

type Props = {
  handleCartUpdate: () => void
}

const FormSave = ({ handleCartUpdate }: Props) => {
  const { t } = useTranslation()
  const { configurationId } = useParams()
  const navigateTo = useNavigate()
  const {
    uid,
    conditions,
    isApproved,
    localCart,
    user,
    reference,
    isEdited,
    canSeePrices,
    summary,
    setUid,
    setName,
    setLocalCart,
    setReference,
    setShowDialog,
    setDialogActions,
    setDisabledDialogActions,
    setIsEdited
  } = useBoundStore(
    (state: StoreState) => ({
      uid: state.uid,
      reference: state.reference,
      conditions: state.conditions,
      isApproved: state.isApproved,
      localCart: state.localCart,
      user: state.user,
      isComplete: state.isComplete,
      isLoaded: state.isLoaded,
      isEdited: state.isEdited,
      canSeePrices: state.user?.canSeePrices,
      summary: state.summary,
      setUid: state.setUid,
      setName: state.setName,
      setLocalCart: state.setLocalCart,
      setReference: state.setReference,
      setShowDialog: state.setShowDialog,
      setDialogActions: state.setDialogActions,
      setDisabledDialogActions: state.setDisabledDialogActions,
      setIsEdited: state.setIsEdited
    }),
    shallow
  )

  const [acceptNonApproved, setAcceptNonApproved] = useState(false)
  const [hidePrices, setHidePrices] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [savedPdfUrl, setSavedPdfUrl] = useState('')

  const [showPdfDisabled, setShowPdfDisabled] = useState(false)
  const [saveAndAddToCartDisabled, setSaveAndAddToCartDisabled] =
    useState(false)
  const [addToCartDisabled, setAddToCartDisabled] = useState(false)

  useEffect(() => {
    if (!savedPdfUrl.length && summary.pdfUrl?.length) {
      setSavedPdfUrl(summary.pdfUrl)
    }
  }, [summary])

  const showSaveDialog = () => {
    if (user) {
      setShowDialog('SaveLoadedDialog')
      setDialogActions({
        SaveNew: saveNew,
        Save: save
      })
    } else {
      saveAsGuest()
    }
  }

  const saveAsGuest = () => {
    saveConfiguration(handleSaveAsGuestResponse)
  }

  const handleSaveAsGuestResponse = (res: AxiosResponse<SaveResponse>) => {
    setReference(res.data.reference ?? '')
    setSavedPdfUrl(res.data.pdfUrl ?? '')
    setShowDialog('SaveChangesAsGuestSuccessDialog')
    setDialogActions({
      closeConfigurator: () => {
        setShowDialog(false)
      }
    })
    setIsSaved(true)
    setIsEdited(false)
  }

  const saveAndAddToCart = () => {
    setSaveAndAddToCartDisabled(true)
    saveConfiguration(addToCartAfterSave)
  }

  const addToCartAfterSave = async (res: AxiosResponse<SaveResponse>) => {
    if (res.data.reference) {
      setIsEdited(false)
      setIsSaved(true)
      setSavedPdfUrl(res.data.pdfUrl ?? '')
      setReference(res.data.reference)
      addToCart(res.data.reference)
    }
  }

  const save = () => {
    setDisabledDialogActions({
      saveNew: true,
      save: true
    })
    saveConfiguration(handleSaveResponse)
  }

  const saveNew = async () => {
    setDisabledDialogActions({
      saveNew: true,
      save: true
    })
    await setName(conditions.name + ' kopia')
    await setUid(uuidv4())
    saveConfiguration(handleSaveNewResponse)
  }

  const handleSaveNewResponse = (res: AxiosResponse<SaveResponse>) => {
    setReference(res.data.reference ?? '')
    setSavedPdfUrl(res.data.pdfUrl ?? '')
    setShowDialog('SaveChangesAsNewSuccessDialog')
    setDialogActions({
      closeConfigurator: () => {
        setShowDialog(false)
      }
    })
    setIsSaved(true)
    setIsEdited(false)
    setDisabledDialogActions({
      saveNew: false,
      save: false
    })
  }

  const handleSaveResponse = (res: AxiosResponse<SaveResponse>) => {
    setReference(res.data.reference ?? '')
    setSavedPdfUrl(res.data.pdfUrl ?? '')
    setShowDialog('SaveChangesSuccessDialog')
    setDialogActions({
      closeConfigurator: () => {
        setShowDialog(false)
      }
    })
    setIsSaved(true)
    setIsEdited(false)
    setDisabledDialogActions({
      saveNew: false,
      save: false
    })
  }

  const addToCart = (ref?: string) => {
    if (ref) {
      setAddToCartDisabled(true)
      if (user) {
        solarPanelClient
          .post('/add-to-cart', {
            reference: ref,
            quantity: 1
          })
          .then(handleAddToCartResponse)
          .catch((error) => {
            setShowDialog('ErrorDialog')
            setSaveAndAddToCartDisabled(false)
            setAddToCartDisabled(false)
            Sentry.captureException(error)
          })
      } else {
        setLocalCart({
          ...localCart,
          [uid]: {
            reference: ref,
            quantity: 1
          }
        })
        handleAddToCartResponse()
      }
    }
  }

  const handleAddToCartResponse = () => {
    setShowDialog('AddedToCartDialog')
    setDialogActions({
      NavigateToCart: () => {
        navigateTo('/cart')
        setShowDialog(false)
      }
    })
    setSaveAndAddToCartDisabled(false)
    setAddToCartDisabled(false)
    handleCartUpdate()
  }

  const saveAndOpenPdf = () => {
    setShowPdfDisabled(true)
    saveConfiguration((res: AxiosResponse<SaveResponse>) => {
      if (res.data.reference && res.data.pdfUrl) {
        setReference(res.data.reference)
        openPdf(res.data.pdfUrl)
      }
    })
  }

  const openPdf = (pdfUrl: string) => {
    if (hidePrices) {
      pdfUrl += '&hide-prices=1'
    }
    window.open(pdfUrl, '_blank')?.focus()
    setShowPdfDisabled(false)
  }

  useEffect(() => {
    if (reference !== undefined) {
      setIsSaved(true)
    }
  }, [reference])

  return (
    <div className="mb-6 flex w-full">
      {!isApproved ? (
        <div className="pr-14">
          <p className="mb-6 text-base italic">
            {t(
              'Denna konfiguration godkänns inte av Weland Stål. Om du önskar gå vidare ändå friskriver sig Weland Stål ansvar enligt Friskrivning takfästen.'
            )}
          </p>
          <ExternalCheckbox
            name="acceptNonApproved"
            label={t('Jag godkänner detta och vill gå vidare')}
            isChecked={acceptNonApproved}
            onChange={() => setAcceptNonApproved(!acceptNonApproved)}
          />
        </div>
      ) : null}
      <div
        className={cn(
          'flex max-w-xs flex-wrap gap-4 pl-6',
          isApproved && 'ml-auto',
          !isApproved && !acceptNonApproved && 'opacity-50'
        )}
      >
        <h3 className="text-lg font-bold">{t('Namn på konfiguration')}</h3>
        <ExternalInput
          name="name"
          className="w-full"
          placeholder={t('Namn') || ''}
          disabled={!isApproved && !acceptNonApproved}
          value={conditions.name}
          onChange={(event) => setName(event.target.value)}
        />

        <div className="flex w-full justify-between">
          {configurationId === undefined && reference === undefined ? (
            <>
              <Button
                disabled={!isApproved && !acceptNonApproved && showPdfDisabled}
                variant="ghost"
                onClick={saveAndOpenPdf}
              >
                <FontAwesomeIcon
                  icon={faArrowDownToLine}
                  className="mr-2"
                />
                {t('PDF')}
              </Button>
              <Button
                disabled={
                  !isApproved && !acceptNonApproved && saveAndAddToCartDisabled
                }
                onClick={saveAndAddToCart}
              >
                {t('Lägg till i projektkorg')}
              </Button>
            </>
          ) : (
            <>
              {isSaved && !isEdited ? (
                <>
                  <Button
                    disabled={!isApproved && !acceptNonApproved}
                    variant="ghost"
                    onClick={() => openPdf(savedPdfUrl)}
                  >
                    <FontAwesomeIcon
                      icon={faArrowDownToLine}
                      className="mr-2"
                    />
                    {t('PDF')}
                  </Button>
                  <Button
                    disabled={
                      !isApproved && !acceptNonApproved && addToCartDisabled
                    }
                    onClick={() => addToCart(reference)}
                  >
                    {t('Lägg till i projektkorg')}
                  </Button>
                </>
              ) : (
                <Button
                  disabled={!isApproved && !acceptNonApproved}
                  onClick={showSaveDialog}
                >
                  {t('Spara ändringar')}
                </Button>
              )}
            </>
          )}
        </div>
        {(canSeePrices &&
          configurationId === undefined &&
          reference === undefined) ||
        (canSeePrices && isSaved && !isEdited) ? (
          <ExternalCheckbox
            name="showPrices"
            label={t('Visa priser i rapport')}
            className="mt-2"
            isChecked={!hidePrices}
            onChange={() => setHidePrices(!hidePrices)}
          />
        ) : null}
      </div>
    </div>
  )
}

export default FormSave
