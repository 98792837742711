import { useTranslation } from 'react-i18next'
import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import Button from '../buttons/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { StoreState, useBoundStore } from '../../store'
import { Trans } from 'react-i18next'

const StartPage = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))
  useEffect(() => {
    if (sessionStorage.getItem('confirmedGuest') !== 'true' && user === null) {
      navigateTo('/login')
    }
  }, [])
  return (
    <PageWrapper
      hideBackButton
      className=" justify-center"
    >
      <SideBar />
      <div className="max-w-md text-center">
        <h1 className="heading-l mb-6">{t('Ny konfiguration')}</h1>
        <Trans i18nKey="start_page_info">
          <p className="mb-10">
            Använd ritverktyget för att få en komplett materialspecifikation. Om
            du behöver hjälp är du välkommen att kontakta oss på{' '}
            <strong>0321-261 60</strong> för vägledning och uträkning.
          </p>
        </Trans>
        <Button onClick={() => navigateTo('/system')}>
          {t('Starta kalkylatorn')}
        </Button>
      </div>
    </PageWrapper>
  )
}

export default StartPage
