import { Circle, Group } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'

type Props = {
  position: Position
  railAngle: number
  scale: number
}

const Screws = ({ position, railAngle, scale }: Props) => {
  const circleRadius = 1.5 * scale
  const mountLength = 7 * scale
  const mountWidth = 4 * scale
  const [width, height] =
    railAngle == 0 ? [mountLength, mountWidth] : [mountWidth, mountLength]
  const [offsetX, offsetY] = [width / 2, height / 2]
  const [circleX1, circleY1] =
    railAngle == 0 ? [2 * scale, 2 * scale] : [2 * scale, 2 * scale]
  const [circleX2, circleY2] =
    railAngle == 0 ? [5 * scale, 2 * scale] : [2 * scale, 5 * scale]
  const color = '#C00D1E'

  const scaledPosition = scaleMmToPixels<Position>(position)

  return (
    <Group
      x={scaledPosition.x}
      y={scaledPosition.y}
      offsetX={offsetX}
      offsetY={offsetY}
    >
      <Circle
        radius={circleRadius}
        fill={color}
        x={circleX1}
        y={circleY1}
      />
      <Circle
        radius={circleRadius}
        fill={color}
        x={circleX2}
        y={circleY2}
      />
    </Group>
  )
}

export default Screws
